import React, { useEffect, useState } from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./add_service.styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ServiceManagment from "../../../services/ServiceManagment";
import IconHouseDesign from "../../../assets/images/admin/project_categories/house-design.png";

import { RoomTypes,NoOfTypes,stairLocation,designType,otherFacing,facingDropdown,plotArea,buildingTypes } from './dataArray';
export const BasicInfo = ({ form, setForm,nextStepBasicInfo}) => {
  const [selectedRoomType, setSelectedRoomType] = useState('');
  const [projectTypes, SetProjectTypes] = useState([]);
  const [key, setKey] = useState("home");
  const handleRadio = (project, name, type) => {
    setForm({ ...form, [type]: { ...form[type], [name]: project.value } });
  };
  const handleProjectTypeRadio = (project, name, type) => {
    setForm({ ...form, [type]: { ...form[type], [name]: project.id } });
  };
  const handleDropdownChange = (selectedValue, type, subtype) => {
    setForm({ ...form, [type]: { ...form[type], [subtype]: selectedValue } });
  };

  const handlRoomTypeCheckBox = (roomType, name, type) => {
    let roomTypes = form[type][name] ? form[type][name] : [];
    if (!roomTypes.filter((item) => item.value === roomType.value)?.length) {
      roomTypes.push(roomType);
    }
    else {
      roomTypes = roomTypes.filter((item) => item.value !== roomType.value);
    }
    setForm({ ...form, [type]: { ...form[type], [name]: roomTypes } });
  };

  const handleChange = (e, type) => {
    setForm({ ...form, [type]: { ...form[type], [e.target.name]: e.target.value } });
  }



  const fetchAllProjectCategory = async () => {
    try {
      const response = await ServiceManagment.getProjectCategory();
      if (response.status === 200) {
        SetProjectTypes(response.data);
      }
    } catch (error) {
      console.error('Error fetching project categories:', error);
    }
  };


  useEffect(() => {
    fetchAllProjectCategory();
  }, []);


  return (
    <>
      <Form className="ServiceForm" onSubmit={nextStepBasicInfo}>
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Label>
            Name of Project <span className="required">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            name="project_name"
            value={form.basic.project_name}
            rows={3}
            placeholder="Design of 10ft*15ft"
            required
            onChange={(e) => handleChange(e, 'basic')}
          />
        </Form.Group>
        {/* Category of Project form goup*/}
        <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
          <Form.Label>
            Category of Project <span className="required">*</span>
          </Form.Label>
          <div className="mt-1">
            <Row className="gx-3 gy-4">
              
              {projectTypes?.map((project) => (
                <Col md="auto" key={project.id}>
                    <label  onClick={() => handleProjectTypeRadio(project, "project_category", "basic")}className={`project-category ${form?.basic?.project_category === project.id ? 'active' : ''}`}>
                    <input
                      type="radio"
                      name="project_category"
                      value={project.id}
                      style={{opacity:0}}
                      checked={form?.basic?.project_category === project.id}
                      required
                    />
                     <img src={IconHouseDesign} alt="Icon Design" /> {project.name}
                    </label>
                   
                  {/* </div> */}
                </Col>
              ))}

            </Row>
          </div>
          {/* <input type="hidden" name="selectedCategory" name= value={selectedCategory} /> */}
        </Form.Group>
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Project Type <span className="required">*</span>
          </Form.Label>
          <Row className="mt-1">
            <Col>
              <Form.Select aria-label="Default select example" name="building_type" required value={form?.basic?.building_type} onChange={(e) => handleDropdownChange(e.target.value,'basic','building_type')}>
                <option value="">Building Type</option>
                <option value="residential">Residential</option>
                <option value="commercial">Commercial</option>
                <option value="institutional">Institutional</option>
                <option value="agriculture">Agriculture</option>
                <option value="government buildings">Government Buildings</option>
                <option value="religious">Religious</option>
                <option value="industrial">Industrial</option>
                <option value="offices">Offices</option>
              </Form.Select>
            </Col>
            {form?.basic?.building_type && buildingTypes[form?.basic?.building_type]?.length &&
            <Col>
              <Form.Select aria-label="Default select example" value={form?.basic?.extended_building_type}
                onChange={(e) => handleDropdownChange(e.target.value, 'basic', 'extended_building_type')}     
              >
                <option>Select Extended Type</option>
                {buildingTypes[form?.basic?.building_type]?.map((building) => (
                  <option value={building.value}>{building.name}</option>
                ))}
              </Form.Select>
            </Col>
           }
          </Row>
        </Form.Group>
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
          Design Type <span className="required">*</span>
          </Form.Label>
          <Row className="mt-1">
            <Col>
              <Form.Select aria-label="Default select example"
              value={form?.basic?.design_type}  onChange={(e) => handleDropdownChange(e.target.value, 'basic', 'design_type')}     
              >
                <option>Select Design Type</option>
                {designType?.map((design) => (
                  <option value={design.value}>{design.name}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        {/* Room Type form goup*/}
        {form?.basic?.project_category === 3 && (
  <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
    <Form.Label>
      Room Type <span className="lebel-sub-text">(Only for Interiors)</span>
      <span className="required">*</span>
    </Form.Label>
    <div className="mt-1">
      <Row className="gx-3 gy-4">
        {RoomTypes?.map((roomType) => (
          <Col md="auto" key={roomType.value}>
            <div
              className={`project-category ${form?.basic?.roomtype_category == roomType.value  ? 'active' : ''}`}
              onClick={() => handleRadio(roomType, "roomtype_category", "basic")}
            >
              <input
                type="radio"
                name={roomType.name}
                value={roomType.value}
                style={{ display: 'none' }}
                checked={form?.basic?.roomtype_category == roomType.value}
              />
              <img src={roomType.icon} alt="Icon Design" /> {roomType.name}
            </div>
          </Col>
        ))}
        {/* Add similar code for other room types */}
      </Row>
    </div>
  </Form.Group>
)}

        {/* Plot Area form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Plot Area <span className="required">*</span>
          </Form.Label>

          <div className="mt-1">
            <Row className="gx-3 gy-4">
              {plotArea?.map((plotType) => (
                <Col md="auto" key={plotType.value}>
                  <div
                    className={`plot-area-item  ${form?.basic?.plot_Areatype == plotType.value ? 'active' : ''}`}
                    onClick={() => handleRadio(plotType, "plot_Areatype", "basic")}
                  >{plotType?.name}
                    <input
                      type="radio"
                      name={plotType?.name}
                      value={plotType?.value}
                      style={{ display: 'none' }}
                      checked={form?.basic?.plot_Areatype == plotType.value}
                    />
               
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Form.Group>
        {/* Covered Area form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Covered Area <span className="required">*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name='covered_area'
            value={form?.basic?.covered_area}
            onChange={(e) => handleChange(e, 'basic')}
            placeholder="Total Covered Area in Sq. ft."
          />
        </Form.Group>
        {/* Plot Size  form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Plot Size <span className="required">*</span>
          </Form.Label>
          <Row className="mt-1">
            <Col>
              <Form.Control type="text" name='plot_size_length'onChange={(e) => handleChange(e, 'basic')} value={form?.basic?.plot_size_length} placeholder="Length in ft." />
            </Col>
            <Col>
              <Form.Control type="text" name='plot_size_width' onChange={(e) => handleChange(e, 'basic')} value={form?.basic?.plot_size_width} placeholder="Width in ft." />
            </Col>
          </Row>
        </Form.Group>
        {/* BHK  form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            BHK <span className="required">*</span>
          </Form.Label>

          <div className="mt-1">
            <Row className="gx-3 gy-4">
              {NoOfTypes?.map((NoOfType) => (
                <Col md="auto" key={NoOfType.value}>
                  <div
                    className={`bhk-item ${form?.basic?.bhk_type == NoOfType.value ? 'active' : ''}`}
                    onClick={() => handleRadio(NoOfType, "bhk_type", "basic")}
                  >
                    <input
                      type="radio"
                      name={NoOfType?.name}
                      value={NoOfType?.value}
                      style={{ display: 'none' }}
                      checked={form?.basic?.bhk_type == NoOfType.value}
                    />
                    <label
                      title=""
                      htmlFor="inline-radio-1"
                      className="form-check-label"
                    >
                      {NoOfType?.name} BHK
                    </label>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Form.Group>

        {/* Facing form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Facing <span className="required">*</span>
          </Form.Label>
          <Row className="mt-1">
            <Col>
              <Form.Select aria-label="Default select example" value={form?.basic?.facing}
                onChange={(e) => handleDropdownChange(e.target.value, 'basic', 'facing')}              >
                <option value="">Select Facing</option>
                {facingDropdown?.map((face) => (
                  <option value={face.value}>{face.name}</option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Select aria-label="Default select example" value={form?.basic?.other_facing}
                onChange={(e) => handleDropdownChange(e.target.value, 'basic', 'other_facing')}              >
                <option value="">Other Facing</option>
                {otherFacing?.map((face) => (
                  <option value={face.value}>{face.name}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        {/* Number of Floors*  form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Number of Floors <span className="required">*</span>
          </Form.Label>
          <div className="mt-1">
            <Row className="gx-3 gy-4">
              {NoOfTypes?.map((NoOfType) => (
                <Col md="auto" key={NoOfType.value}>
                  <div
                    className={`bhk-item ${form?.basic?.floor_type == NoOfType.value ? 'active' : ''}`}
                    onClick={() => handleRadio(NoOfType, "floor_type", "basic")}
                  >
                    <input
                      type="radio"
                      name={NoOfType?.name}
                      value={NoOfType?.value}
                      style={{ display: 'none' }}
                      checked={form?.basic?.floor_type == NoOfType.value}
                    />
                    <label
                      title=""
                      htmlFor="inline-radio-1"
                      className="form-check-label"
                    >
                      {NoOfType?.name}
                    </label>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Form.Group>
        {/* Bedroom**  form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Bedroom <span className="required">*</span>
          </Form.Label>
          <div className="mt-1">
            <Row className="gx-3 gy-4">
              {NoOfTypes?.map((NoOfType) => (
                <Col md="auto" key={NoOfType.value}>
                  <div
                    className={`bhk-item ${form?.basic?.bedroom_type == NoOfType.value ? 'active' : ''}`}
                    onClick={() => handleRadio(NoOfType, "bedroom_type", "basic")}
                  >
                    <input
                      type="radio"
                      name={NoOfType?.name}
                      value={NoOfType?.value}
                      style={{ display: 'none' }}
                      checked={form?.basic?.bedroom_type == NoOfType.value}
                    />
                    <label
                      title=""
                      htmlFor="inline-radio-1"
                      className="form-check-label"
                    >
                      {NoOfType?.name}
                    </label>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Form.Group>

        {/* Kitchen  form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Kitchen<span className="required">*</span>
          </Form.Label>
          <div className="mt-1">
            <Row className="gx-3 gy-4">
              {NoOfTypes?.map((NoOfType, index) => (
                index !== 4 && (
                  <Col md="auto" key={NoOfType.value}>
                    <div
                      className={`bhk-item ${form?.basic?.kitchen_type == NoOfType.value ? 'active' : ''}`}
                      onClick={() => handleRadio(NoOfType, "kitchen_type", "basic")}
                    >
                      <input
                        type="radio"
                        name={NoOfType?.name}
                        value={NoOfType?.value}
                        style={{ display: 'none' }}
                        checked={form?.basic?.kitchen_type == NoOfType.value}
                      />
                      <label
                        title=""
                        htmlFor="inline-radio-1"
                        className="form-check-label"
                      >
                        {NoOfType?.name}
                      </label>
                    </div>
                  </Col>
                )
              ))}

            </Row>
          </div>
        </Form.Group>

        {/* Bathroom  form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Bathroom<span className="required">*</span>
          </Form.Label>
          <div className="mt-1">
            <Row className="gx-3 gy-4">
              {NoOfTypes?.map((NoOfType, index) => (
                // Check if the index is not 4 (0-based index, so it's the 5th element)
                index !== 4 && (
                  <Col md="auto" key={NoOfType.value}>
                    <div
                      className={`bhk-item ${form?.basic?.bathroom_type == NoOfType.value ? 'active' : ''}`}
                      onClick={() => handleRadio(NoOfType, "bathroom_type", "basic")}
                    >
                      <input
                        type="radio"
                        name={NoOfType?.name}
                        value={NoOfType?.value}
                        style={{ display: 'none' }}
                        checked={form?.basic?.bathroom_type == NoOfType.value}
                      />
                      <label
                        title=""
                        htmlFor="inline-radio-1"
                        className="form-check-label"
                      >
                        {NoOfType?.name}
                      </label>
                    </div>
                  </Col>
                )
              ))}

            </Row>
          </div>
        </Form.Group>
        {/* Stair Location  form goup*/}
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>
            Stair Location<span className="required">*</span>
          </Form.Label>
          <div className="mt-1">
            <Row className="gx-3 gy-4">
              {stairLocation?.map((stairType) => (
                <Col md="auto" key={stairType.value}>
                  <div
                    className={`bhk-item ${form?.basic?.stair_type === stairType.value ? 'active' : ''}`}
                    onClick={() => handleRadio(stairType, "stair_type", "basic")}
                  >
                    <input
                      type="radio"
                      name={stairType?.name}
                      value={stairType?.value}
                      style={{ display: 'none' }}
                      checked={form?.basic?.stair_type === stairType.value}
                    />
                    <label
                      title=""
                      htmlFor="inline-radio-1"
                      className="form-check-label"
                    >
                      {stairType?.name}
                    </label>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Form.Group>

        {/* Submit Button */}
        <div className="d-grid gap-2 mb-3 mt-5 ">
          <Button type="submit" className="themeBtn" size="lg">
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
}
