import moment from "moment";

export const dateFormat = (date) => {
  if (date) {
    var date = new Date(date);
    var localDate = date.toString()
    return moment(localDate).format("MMM Do YY");
  } else {
    return "";
  }
};
