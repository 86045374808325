import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import "./add_service.styles.scss";
import Button from "react-bootstrap/Button";
import { ButtonLoader } from '../../loader/ButtonLoader';
import { IMAGE_BASE_URL } from '../../../config/AppConfig';
export const Photos = ({ form, setForm,submitServiceFormFunction,setLoading,updateServiceFormFunction,loading }) => {
    const [previewImages, setPreviewImages] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        const updatedPhotos = { ...form['photos'], "service_images": acceptedFiles };
        setForm({ ...form, "photos": updatedPhotos });
        setPreviewImages((prevImages) => [...prevImages, ...acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }))]);
    }, [form, setForm]);
    const removeImage = (index) => {
        const updatedImages = [...previewImages];
        updatedImages.splice(index, 1);
        setPreviewImages(updatedImages);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
    });

    return (
        <div>
            <div {...getRootProps()} className="dropzoneStyles">
                <input {...getInputProps()} />
                <p>Drag & drop photos here, or click to select photos</p>
            </div>
            <div className='d-flex flex-wrap'>
                {previewImages.map((file, index) => (
                    <div key={file.name} className="imageContainerStyles">
                        <img
                            src={file.preview}
                            alt={file.name}
                            className="previewImageStyles"
                        />
                        <button
                            onClick={() => removeImage(index)}
                            className="deleteButtonStyles"
                        >
                            &times;
                        </button>
                    </div>
                ))}
                {form?.photos?.old_images?.map((file, index) => (
                    <div key={file.name} className="imageContainerStyles">
                    <img src={`${IMAGE_BASE_URL}/${file.name}`} alt='' height={"200px"} width={"250px"} />
                        <button
                            onClick={() => removeImage(index)}
                            className="deleteButtonStyles"
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>
            <div className="d-grid gap-2 mb-3 mt-5 ">
            <Button
  type="submit"
  onClick={
    loading
      ? () => {} // Disable the onClick event when loading
      : form?.photos?.old_images
      ? updateServiceFormFunction // Call updateServiceFormFunction when old_images exist and are not empty
      : submitServiceFormFunction // Call submitServiceFormFunction when old_images do not exist or are empty
  }
  className={`themeBtn ${form?.photos?.old_images ? 'updatedBtn' : ''}`}
  size="lg"
>
  {loading ? (
    <ButtonLoader LoaderWidth='50px' LoaderHeight='50px' />
  ) : (
    form?.photos?.old_images ? 'Update' : 'Submit'
  )}
</Button>

            </div>
        </div>

    );
};

export default Photos;
