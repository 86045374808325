import { Link } from "react-router-dom";
import Logo from "../../../assets/images/Logo-Planning.png";
import styles from './sidebar.module.scss'
import Dashboard from '../../../assets/images/svg/sidebar_icons/dashboard.svg'
import Leads from '../../../assets/images/svg/sidebar_icons/leads.svg'
import Listing from '../../../assets/images/svg/sidebar_icons/listing.svg'
import Packages from '../../../assets/images/svg/sidebar_icons/packages.svg'
import Users from '../../../assets/images/svg/sidebar_icons/users.svg'
import Calender from '../../../assets/images/svg/sidebar_icons/calender.svg'
import Message from '../../../assets/images/svg/sidebar_icons/massages.svg'
import Sample from '../../../assets/images/svg/sidebar_icons/sample.svg'
import ArrowRight from '../../../assets/images/svg/sidebar_icons/arrow_right.svg'
import Menu from '../../../assets/images/svg/sidebar_icons/menu.svg'
import { useState } from "react";

export const Sidebar = () => {
  const [currentTab, setCurrentTab] = useState('Dashboard')
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);

  const listItmsarr = [
    { id: 1, title: "Dashboard", icon: Dashboard, path: "/dashboard" },
    { id: 2, title: "Leads", icon: Leads, path: "/leads-view" },
    { id: 3, title: "Listings", icon: Listing, path: "/service-listing" },
    { id: 4, title: "Packages", icon: Packages, path: "/packages" },
    { id: 4, title: "Orders", icon: Packages, path: "/manage-orders" },
    { id: 5, title: "Users", icon: Users, path: "/user-management/members" },
    { id: 6, title: "Calendar", icon: Calender, path: "/calendar-view" },
    { id: 7, title: "Message", icon: Message, path: "" },
    { id: 8, title: "Samples", icon: Sample, path: "/sample-upload" },
    { id: 9, title: "Reports", icon: Sample, path: "" },
    { id: 10, title: "Reviews", icon: Sample, path: "" },
    { id: 11, title: "More", icon: Sample, path: "" }
  ]
  const handleCurrentTab = (data) => {
    setCurrentTab(data.title)
  }

  const toggleButtonClick = () => {
    setIsSidebarHidden(!isSidebarHidden);
  };

  return (
    <div className={`col-auto col-md-3 col-xl-1 px-0 ${isSidebarHidden ? styles.sidebar_hidden : styles.sidebar_main}`}>
            <div className={`d-flex flex-column align-items-center align-items-sm-start text-white min-vh-100`}>
        <ul
          className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start w-100"
          id="menu"
        >
          <div className={styles.top_icon}>
            <img onClick={toggleButtonClick} src={Menu} alt="" height="" />

            <Link to="/" className="d-flex align-items-center mb-md-0 me-md-auto text-white text-decoration-none">
              <img src={Logo} alt="" height="" width="123px" />
            </Link>
          </div>
          {listItmsarr.map((itm) => {
            return (
              <li className={`nav-item ${currentTab === itm.title ? 'active' : ""}`} key={itm.id} onClick={() => handleCurrentTab(itm)}>
                <Link to={`${itm.path}`} className="nav-link px-0 d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center gap-2">
                    <img src={itm.icon} alt="" className="ms-2" />
                    <span className="ms-1 d-none d-sm-inline">{itm.title}</span>
                  </div>
                  <div className={`align-items-center gap-1 d-flex`}>
                    {itm.title === 'Message' &&
                      <div className={styles.counter}>
                        8
                      </div>
                    }
                    <img src={ArrowRight} alt="" className={`ms-2  ${styles.side_counter}`} />
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
        <hr />
      </div>
    </div>
  );
};
