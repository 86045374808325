import React, { useEffect } from "react";
import { AUTH_TOKEN } from "../../config/constants/AuthConstant";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem("first_name");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    navigate("/");
  }, []);

  return <></>;
};

export default Logout;
