import React, { useEffect, useState } from 'react';
import styles from './packages.module.scss';
import { ShowErrorMessage, ShowSuccessMessage } from '../../Notification';
import PackageManagment from '../../../services/PackageManagment';
import { Link } from 'react-router-dom'

export const SideFilter = ({ setselectedPackageStatus, selectedPackageStatus, setSelectedPackage,setSelectedPackageName, selectedPackage }) => {
  const [accordionsOpen, setAccordionsOpen] = useState({});

  const [packageData, setPackageData] = useState([]);

  const toggleAccordion = (accordionName) => {
    setAccordionsOpen((prevState) => ({
      ...prevState,
      [accordionName]: !prevState[accordionName],
    }));
  };

  const getPackageDetails = async () => {
    try {
      const response = await PackageManagment.getCreatedPackage();
      if (response.status === 200) {
        setPackageData(response?.data);
        // ShowSuccessMessage(response.message);
      } else {
        ShowErrorMessage(response.message);
      }
    } catch (error) {
      ShowErrorMessage(error.response.data.message);
    }
  };

  useEffect(() => {
    getPackageDetails();
  }, []);


  useEffect(() => {
    const initialAccordionState = {};
    packageData.forEach((data) => {
      initialAccordionState[data.packageForId] = false;
    });
    setAccordionsOpen(initialAccordionState);
    if (packageData.length > 0) {
      setSelectedPackage(packageData[0].packageForId);
      setSelectedPackageName(packageData[0].packageFor);
    }
  }, [packageData]);
  return (
    <div className='col-4'>
    <div className={styles.filterBox}>
      <div className={styles.filterBoxFirstPart}>
        <div className={styles.text_wrapper}>Show Package</div>
        <div className="">
          {packageData.map((data, index) => (
            <div
              className={`${styles.projectCategory} ${selectedPackage === data.packageForId ? styles.activeCategory : ''}`}
              key={index}
              onClick={() => {
                setSelectedPackage(data.packageForId);
                setSelectedPackageName(data.packageFor);
              }}
                >
              {data?.packageFor}
            </div>
          ))}
        {/* <Link to='/add-package'><div className={styles.projectCategory} >
            + Add Package
          </div> </Link> */}
        </div>
      </div>
      <div className={styles.filterSection}>
        {packageData.map((data, index) => (
          <div key={index}>
            <div className={`filterOption d-flex ${selectedPackage === data.packageForId ? styles.activeCategory : ''}`}>
              <div className={styles.text_wrapper} onClick={() => toggleAccordion(data.packageForId)}>
                {data.packageFor} ({data.totalPackages})
              </div>
              <div className='icon'>{accordionsOpen[data.packageForId] ? '-' : '+'}</div>
            </div>
            {accordionsOpen[data.packageForId] && (
              <div
                className={`${styles.accordion_content} ${selectedPackage === data.packageForId ? styles.activeAccordion : ''
                  }`}
              >
                <div
                  className={`${styles.text_wrapper_accordian} ${selectedPackageStatus === 'Active' ? styles.selectedStatus : ''}`}
                  onClick={() => setselectedPackageStatus('Active')}
                >
                  Active package ({data.activeCount})
                </div>
                <div
                  className={`${styles.text_wrapper_accordian} ${selectedPackageStatus === 'Delete' ? styles.selectedStatus : ''}`}
                  onClick={() => setselectedPackageStatus('Delete')}
                >
                  Deleted package ({data.deleteCount})
                </div>
                <div
                  className={`${styles.text_wrapper_accordian} ${selectedPackageStatus === 'New' ? styles.selectedStatus : ''}`}
                  onClick={() => setselectedPackageStatus('New')}
                >
                  New package ({data.newCount})
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};
