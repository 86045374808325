import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class ServiceType extends Component {
    render() {
        const options = {
            theme: "light2",
            animationEnabled: true,
            exportFileName: "New Year Resolutions",
            exportEnabled: true,
            title: {
                text: ""
            },
            data: [{
                type: "pie",
                showInLegend: true,
                legendText: "{label}",
                toolTipContent: "{label}: <strong>{y}%</strong>",
                indexLabel: "{y}%",
                indexLabelPlacement: "inside",
                dataPoints: [
                    { y: 45, label: "House Design" },
                    { y: 45, label: "3D Elevation" },
                    { y: 10, label: "Floor Plans" },

                ]
            }]
        }
        return (
            <div>
                <CanvasJSChart options={options} />
            </div>
        );
    }
}

export default ServiceType;
