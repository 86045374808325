import { dateFormat } from "../../../../utills/helpers/CommonHelper";
import Dropdown from "react-bootstrap/Dropdown";
import * as Icon from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

const ListItem = ({ list, handleEdit }) => {
  return (
    <>
      {list.length > 0 &&
        list.map((value, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{value.full_name}</td>
              <td>{value.email}</td>
              <td>
                {value.country_code} {value.mobile_no}
              </td>
              <td>{value.address}</td>
              <td>{value.status}</td>
              <td>{value.role}</td>
              <td>{dateFormat(value.created_at)}</td>
              <td>
                <Button
                  size="md"
                  className="mb-4 btnEdit"
                  onClick={() => handleEdit(value.id)}
                >
                  <Icon.Pencil />
                </Button>{" "}
                <Button
                  variant="danger"
                  size="md"
                  className="mb-4"
                  onClick={() => handleEdit(value.id)}
                >
                  <Icon.Trash />
                </Button>
                {/* <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">Actions</Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => handleEdit(value.id)}>
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Change Status
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="4">Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default ListItem;
