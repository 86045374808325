import fetch from "./FetchInterceptor";
const userService = {};

userService.getUsers = function (data) {
  return fetch({
    url: "admin/get_users",
    method: "get",
    data: data,
  });
};

userService.addUser = function (data) {
  return fetch({
    url: "admin/add_user",
    method: "post",
    data: data,
  });
};

userService.getUser = function (id) {
  return fetch({
    url: `admin/users/${id}`,
    method: "get",
    data: {},
  });
};

userService.updateUser = function (id, data) {
  return fetch({
    url: `admin/users/${id}`,
    method: "post",
    data: data,
  });
};
export default userService;
