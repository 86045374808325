import React, { useState, useEffect } from 'react';
import styles from './lisitng.module.scss';
import Dowload from '../../../assets/images/svg/dowload.svg';
import DummyImg from '../../../assets/images/svg/dummy.svg';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import ServiceManagment from '../../../services/ServiceManagment';
import { ShowErrorMessage, ShowSuccessMessage } from '../../../components/Notification';
import { IMAGE_BASE_URL } from '../../../config/AppConfig';
import { dateFormat } from '../../../utills/helpers/CommonHelper';
import ReactPaginate from 'react-paginate';
import * as XLSX from 'xlsx';
import MenuItem from '@mui/material/MenuItem';

export const ListingMain = () => {
    const [projectTypes, SetProjectTypes] = useState([]);
    const [data, setData] = useState([]); // State to store the data
    const [currentPage, setCurrentPage] = useState(1); // State to store the current page number
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const [activeValue, setActiveValue] = useState(projectTypes.length > 0 ? projectTypes[0]?.id : null);
    const fetchData = async (page, activeValue) => {
        try {
            const response = await ServiceManagment.getAllServices(page, activeValue);
            if (response.status === 200) {
                setData(response.data.data);
                console.log(response.data.data, 'herererererre');
                setTotalPages(Math.ceil(response.data.total / response.data.per_page));
            } else {
                ShowErrorMessage("Failed to fetch data.");
            }

        } catch (error) {
            console.error("Error fetching data:", error);
            ShowErrorMessage("Failed to fetch data.");
        }
    };

    useEffect(() => {
        fetchData(currentPage, activeValue);
    }, [currentPage, activeValue]);

    useEffect(() => {
        setActiveValue(projectTypes.length > 0 ? projectTypes[0]?.id : null);
    }, [projectTypes]);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected + 1);
    };
    const handleEditData = (value) => {
        navigate('/service-management', { state: { type: 'editService', ServiceData: value } });
    }
    const handleDeleteData = async (value) => {
        try {
            const response = await ServiceManagment.changeStatus(value);
            if (response.status === 200) {
                ShowSuccessMessage(response?.message)
            } else {
                ShowErrorMessage("Failed to fetch data.");
            }

        } catch (error) {
            console.error("Error fetching data:", error);
            ShowErrorMessage("Failed to fetch data.");
        }
    }
    const handleDownload = () => {
        // Create a worksheet
        const ws = XLSX.utils.aoa_to_sheet([
            ['ID', 'Starting Price', 'Status', 'Project Name', 'Type', 'Plot Size', 'Vastu Compliance', 'Added On', 'Plot Area', 'Building Category', 'Style', 'Facing'],
            ...data.map((item) => {
                const basicPackage = item.package_data.find((packageInfo) => packageInfo.package_types === 'basic');
                return [
                    item.id,
                    basicPackage ? `${basicPackage.package_pricing}/- Starting` : 'N/A',
                    item.status,
                    item.project_name,
                    'Single Storey House', // Adjust based on your data structure
                    `${item.plot_size_length}ft X ${item.plot_size_width} ft`,
                    item.vastu_compliances,
                    dateFormat(item.created_at),
                    item.covered_area,
                    item.project_building_type,
                    item.project_design_type,
                    item.plot_main_facing
                ];
            }),
        ]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'ListingData');
        XLSX.writeFile(wb, 'ListingData.xlsx');
    };

    const fetchAllProjectCategory = async () => {
        try {
            const response = await ServiceManagment.getProjectCategory();
            if (response.status === 200) {
                SetProjectTypes(response.data);
            }
        } catch (error) {
            console.error('Error fetching project categories:', error);
        }
    };

    useEffect(() => {
        fetchAllProjectCategory();
    }, []);
    const handleSpanClick = (value) => {
        setActiveValue(value);
    };
    console.log(activeValue, 'active');

    return (
        <div className={`${styles.ListingMain} row`}>
            <div className='col-8'>
                <h1>Listing Details</h1>
                <p>Welcome back to Planningg.homes Dashboard</p>
            </div>
            <div className='col-4'>
                <button className={styles.downloadbtn} onClick={handleDownload}>
                    Download
                    <img src={Dowload} alt='svg' className='ms-3' />
                </button>
                <Link to='/service-management'> <button className={styles.addbtn}>+ Add Listing</button></Link>
            </div>
            <div className={`${styles.listint_data}`}>
                <Row>
                    <Col sm={8}>
                        {data.length > 0 ? (
                            data.map((itm, i) => {
                                return (
                                    <div className={styles.item_card} key={itm.id}>
                                        <Row>
                                            <Col md={3} className='pe-0'>
                                                <div className={styles.itm_no}>ID:{itm.id}</div>
                                                <div className={styles.img_card}>
                                                    <img src={`${IMAGE_BASE_URL}/${JSON.parse(itm.photos)[0]?.name}`} alt='' height={"200px"} width={"250px"} />
                                                </div>
                                            </Col>
                                            <Col md={9} className='ps-0'>
                                                <div className={styles.top_data}>
                                                    <div className='d-flex algin-items-center justify-content-between ps-2 pt-2 pe-4'>
                                                        <div className='d-flex align-items-center'>
                                                            {itm?.package_data?.map((price, p) =>
                                                                price.package_types === 'basic' && (
                                                                    <h4 key={p}>{price.package_pricing} /-</h4>
                                                                )
                                                            )}

                                                            <h5>Starting</h5>
                                                        </div>
                                                        <label className={`${itm.status === 'Active' ? 'text-primary' : itm.status === 'Delete' ? 'text-danger' : ''}`}>{itm.status}</label>
                                                    </div>
                                                    <h2 className='mt-2'>{itm.project_name} </h2>
                                                    <Row className='mt-2'>
                                                        <Col sm={4}> <span>Single Storey House</span></Col>
                                                        <Col sm={4}> <span>{itm.plot_size_length}ft X {itm.plot_size_width} ft</span></Col>
                                                        <Col sm={4}></Col>
                                                    </Row>
                                                    <Row className='mt-2'>
                                                        <Col sm={4}> <span>Vaastu Compliance : {itm.vastu_compliances}</span></Col>
                                                        <Col sm={4}> <span>Added on : {dateFormat(itm.created_at)}</span></Col>
                                                        <Col sm={4}></Col>
                                                    </Row>
                                                </div>
                                                <div className={styles.bottom_div}>
                                                    <div className='d-flex align-itms-center justify-content-between ps-2 pe-3'>
                                                        <div className='d-flex align-items-center flex-column'>
                                                            <label>{itm.covered_area} </label>
                                                            <span>Plot Area</span>
                                                        </div>
                                                        <div className='d-flex align-items-center flex-column'>
                                                            <label>{itm.project_building_type} </label>
                                                            <span>Building Category</span>
                                                        </div>
                                                        <div className='d-flex align-items-center flex-column'>
                                                            <label>{itm.project_design_type}</label>
                                                            <span>Style</span>
                                                        </div>
                                                        <div className='d-flex align-items-center flex-column'>
                                                            <label>{itm.plot_main_facing}</label>
                                                            <span>Facing</span>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex align-items-center gap-3 mt-3'>
                                                        <h6 onClick={() => handleEditData(itm)}>Edit</h6>
                                                        <h6 onClick={() => handleDeleteData(itm.id)}>Delist</h6>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })
                        ) : (
                            <div>No service found</div>
                        )}
                    </Col>

                    <Col sm={4}>
                        <div className={styles.right_div}>
                            Show Categories
                            {/* <span style={{ color: "#5e23dc" }}>   Residential </span>
                            <span className='mb-3'>  Commercial  </span> */}
                            {projectTypes.map((data) => (
                                <span
                                    key={data?.id}
                                    style={{
                                        color: activeValue === data?.id ? '#5e23dc' : 'inherit',
                                        cursor: 'pointer', // Add pointer cursor to indicate it's clickable
                                    }}
                                    onClick={() => handleSpanClick(data?.id)}
                                >
                                    {data?.name}
                                </span>

                            ))}
                        </div>
                    </Col>
                </Row>

                {data.length > 0 && (
                    <div className={styles.pagination}>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={totalPages} // Change this to the total number of pages from your backend
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination custom-pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                )}

            </div>
        </div>
    );
};
