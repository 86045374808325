import React from 'react'
import EventList from './EventList';
export const Leads = () => {
  return (
    <div>
<EventList/>

    </div>
  )
}
