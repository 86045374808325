import fetch from "./FetchInterceptor";
const ServiceManagment = {};

ServiceManagment.getProjectCategory = function (data) {
  return fetch({
    url: "admin/get-all-project-category",
    method: "get",
    data: data,
  });
};

ServiceManagment.fileUpload = function (data) {
  return fetch({
    url: "admin/file-upload-for-service",
    method: "post",
    data: data,
  });
};


ServiceManagment.addFAQ = function (data) {
  return fetch({
    url: "admin/create-faq",
    method: "post",
    data: data,
  });
};

ServiceManagment.submitPricing = function (data) {
  return fetch({
    url: "admin/create-service-package",
    method: "post",
    data: data,
  });
};


ServiceManagment.createService = function (data) {
  return fetch({
    url: "admin/create_service",
    method: "post",
    data: data,
  });
};

ServiceManagment.changeStatus = function (id) {
  return fetch({
    url: `admin/delete_service/${id}`,
    method: "post",
  });
};
ServiceManagment.updateService = function (data, id) {
  return fetch({
    url: `admin/update-service/${id}`,
    method: "post",
    data: data,
  });
};

ServiceManagment.getAllServices = function (page = 1,project_category = '') {
  let pageParam = `&page=${page}`;
  let projectCategoryParam = project_category ? `&project_category=${project_category}` : '';
  return fetch({
    url: "admin/get_all_service",
    url: `/get_all_service?${pageParam}${projectCategoryParam}`,
    method: "get",
  });
};


export default ServiceManagment;  