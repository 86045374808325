import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import * as Icon from "react-bootstrap-icons";
import userService from "../../../../services/UserService";
import ListItem from "./listitems";
import { ShowErrorMessage } from "../../../../components/Notification";
import AddUser from "./add_user";
import EditUser from "./edit_user";

export const MembersManagement = () => {
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [addUserKey, setAddUserKey] = useState(0);
  const [list, setList] = useState([
    // { s_no: 1, name: "Mark", email: "Otto", status: "@mdo" },
    // { s_no: 2, name: "Jacob", email: "Thornton", status: "@fat" },
    // { s_no: 3, name: "Larry the Bird", email: "Otto", status: "@twitter" },
    // { s_no: 4, name: "Mark", email: "Otto", status: "@mdo" },
  ]);

  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [editUserKey, setEditUserKey] = useState(0);
  const [editUserData, setEditUserData] = useState([]);

  const fetchAllUsers = async () => {
    const response = await userService.getUsers();

    if (response.success === false) {
      ShowErrorMessage(response.message);
      return false;
    }
    let user_list = response.data;
    if (user_list) {
      let finalData = user_list.map((item) => ({
        ...item,
        full_name: `${item.first_name} ${item.last_name}` || "",
        key: `${item.id}`,
      }));
      user_list = finalData;
    }
    setList(user_list);
  };
  useEffect(() => {
    fetchAllUsers();
  }, [addUserKey, editUserKey]);

  const handleShowAddUserModal = () => setShowAddUserModal(true);

  let active = 1;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }

  const handleEdit = async (id) => {
    const response = await userService.getUser(id);

    if (response.success === false) {
      ShowErrorMessage(response.message);
      return false;
    }
    setEditUserData(response.data);
    setShowEditUserModal(true);
  };
  return (
    <>
      <Card style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title>
            <h1 className="mb-5">Manage Members</h1>
          </Card.Title>
          <Card.Text>
            <Row>
              <Col>
                <Button
                  size="md"
                  className="mb-4 btnAdd"
                  onClick={handleShowAddUserModal}
                >
                  <Icon.Plus /> Add User
                </Button>
              </Col>
              <Col xs lg="3">
                <InputGroup className="mb-4 float-end">
                  <Form.Control
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    {" "}
                    <Icon.Search />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <AddUser
              showAddUserModal={showAddUserModal}
              setShowAddUserModal={setShowAddUserModal}
              addUserKey={addUserKey}
              setAddUserKey={setAddUserKey}
            />
            {editUserData && (
              <EditUser
                showEditUserModal={showEditUserModal}
                setShowEditUserModal={setShowEditUserModal}
                editUserKey={editUserKey}
                setEditUserKey={setEditUserKey}
                editUserData={editUserData}
              />
            )}
            <Table striped bordered hover responsive className="mb-5">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full Name</th>
                  <th>Email</th>
                  <th>Mobile No.</th>
                  <th>Address</th>
                  <th>Status</th>
                  <th>Role</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <ListItem list={list} handleEdit={handleEdit} />
              </tbody>
            </Table>

            <Pagination className="float-end mt-5">{items}</Pagination>
            <br />
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default MembersManagement;
