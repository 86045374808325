import fetch from "./FetchInterceptor";
const SampleManagment = {};

SampleManagment.uploadSample = function (data) {
    return fetch({
      url: "admin/upload-sample",
      method: "post",
      data: data,
    });
  };

 
export default SampleManagment;  