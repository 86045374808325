const routes = {
  loginRoute: "/",
  forgotPasswordRoute: "/forgot-password",
  logoutRoute: "/logout",

  dashboardRoute: "/dashboard",
  // addUserRoute: "/user-management/add",
  // userMangementRoute: "/user-management",
  membersMangementRoute: "/user-management/members",
  userMangementRoute: "/user-management/users",
  serviceMangementRoute: "/service-management",
  EditServicesRoute:"/edit-service",
  PackagesRoute: "/packages",
  AddPackage:"/add-package",
  // EditPackage:"/edit-package",
  ManageOrdersRoute: "/manage-orders",
  ServiceListing:'/service-listing',
  CalendarRoute:'/calendar-view',
  LeadsRoute:'/leads-view',
  SampleUplaod:'/sample-upload',

};
export default routes;
