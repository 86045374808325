import axios from "axios";
import { CALENDARLY_CLIENT_ID, CALENDARLY_CLIENT_SECRET, Token } from "../../../config/AppConfig";

async function fetchCalendarlyData() {
    const userApiOptions = {
        method: 'GET',
        url: 'https://api.calendly.com/users/me',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Token}`
        }
    };

    const userData = await axios.request(userApiOptions);
    console.log(userData,'hrerererereerreerreerreerrerererererreerr');
    if (userData.status === 200) {
        const userResource = userData.data.resource;
        const scheduledEventsApiOptions = {
            method: 'GET',
            url: 'https://api.calendly.com/scheduled_events',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Token}`
            },
            params: {
                organization: userResource?.current_organization,
                user: userResource?.uri,
            }
        };
        const scheduledEvents = await axios.request(scheduledEventsApiOptions);
        return scheduledEvents.data;
    } else {
        console.error('Error fetching user data from Calendarly:', userData);
        throw new Error('Failed to retrieve user data from Calendarly.');
    }
}

export async function getCalendarlyEvents() {
    return fetchCalendarlyData();
}


