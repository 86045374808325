import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import {
  ShowErrorMessage,
  ShowSuccessMessage,
} from "../../../../components/Notification";
import userService from "../../../../services/UserService";

function EditUser({
  showEditUserModal,
  setShowEditUserModal,
  editUserKey,
  setEditUserKey,
  editUserData,
}) {
  console.log(editUserData);
  const handleClose = () => setShowEditUserModal(false);
  const [validated, setValidated] = useState(false);
  const defaultFormData = {
    first_name: "",
    last_name: "",
    email: "",
    country_code: "+91",
    mobile_no: "",
    dob: "",
    job_title: "",
    city: "",
    address: "",
    country: "",
    post_code: "",
    role: "",
    // password: "",
    status: "",
  };
  const [userInputs, setUserInputs] = useState(defaultFormData);

  useEffect(() => {
    const defaultFormData = {
      id: editUserData.id,
      first_name: editUserData.first_name,
      last_name: editUserData.last_name,
      email: editUserData.email,
      country_code: editUserData.country_code,
      mobile_no: editUserData.mobile_no,
      dob: editUserData.dob,
      job_title: editUserData.job_title,
      city: editUserData.city,
      address: editUserData.address,
      country: editUserData.country,
      post_code: editUserData.post_code,
      role: editUserData.role,
      // password: editUserData.password,
      status: editUserData?.status,
    };
    setUserInputs(defaultFormData);
  }, [editUserData]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    // if (userInputs.password === "") {
    //   ShowErrorMessage("Password is required !");
    // }

    const response = await userService.updateUser(editUserData.id, userInputs);
    // console.log(response);
    if (response.success === false) {
      ShowErrorMessage(response.message);
      return false;
    }
    ShowSuccessMessage(response.message);
    setEditUserKey(editUserKey + 1);
    setUserInputs(defaultFormData);
    setShowEditUserModal(false);
  };

  return (
    <>
      <Modal
        show={showEditUserModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title aria-labelledby="example-modal-sizes-title-lg">
            Edit User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Control
                    placeholder="First name"
                    required
                    name="first_name"
                    key="first_name"
                    type="text"
                    value={userInputs.first_name}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        first_name: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Last name"
                    name="last_name"
                    type="text"
                    key="last_name"
                    value={userInputs.last_name}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        last_name: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Control
                    placeholder="Email"
                    required
                    name="email"
                    type="email"
                    key="email"
                    readOnly
                    value={userInputs.email}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        email: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon2">+91</InputGroup.Text>
                    <Form.Control
                      placeholder="Mobile No."
                      aria-label="mobile_no"
                      aria-describedby="basic-addon2"
                      name="mobile_no"
                      required
                      type="text"
                      key="mobile_no"
                      readOnly
                      value={userInputs.mobile_no}
                      onChange={(e) => {
                        setUserInputs({
                          ...userInputs,
                          mobile_no: e.target.value,
                        });
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Control
                    type="date"
                    placeholder="DOB"
                    name="dob"
                    key="dob"
                    value={userInputs.dob}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        dob: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Job Title"
                    name="job_title"
                    key="job_title"
                    value={userInputs.job_title}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        job_title: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Control
                    placeholder="City"
                    name="city"
                    key="city"
                    value={userInputs.city}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        city: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Address"
                    name="address"
                    key="address"
                    value={userInputs.address}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        address: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Control
                    placeholder="Country"
                    name="country"
                    key="country"
                    value={userInputs.country}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        country: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <Form.Control
                    placeholder="Post Code"
                    name="post_code"
                    key="post_code"
                    value={userInputs.post_code}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        post_code: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <Form.Select
                    aria-label="Default select example"
                    required
                    name="role"
                    key="role"
                    value={userInputs.role}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        role: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Role</option>
                    <option value="Admin">Admin</option>
                    <option value="User">User</option>
                  </Form.Select>
                </Col>
                {/* <Col>
                  <Form.Control
                    placeholder="Password"
                    name="password"
                    type="password"
                    key="password"
                    value={userInputs.password}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        password: e.target.value,
                      });
                    }}
                  />
                  <Form.Text className="text-danger">
                    In case of role Admin, Password is required !
                  </Form.Text>
                </Col> */}
                <Col>
                  <Form.Select
                    aria-label="Default select example"
                    required
                    name="role"
                    key="role"
                    value={userInputs?.status}
                    onChange={(e) => {
                      setUserInputs({
                        ...userInputs,
                        status: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Status</option>
                    <option value="New">New</option>
                    <option value="Active">Active</option>
                    <option value="Suspend">Suspend</option>
                  </Form.Select>
                </Col>
              </Row>
              <div className="mt-5 float-end">
                <Button variant="secondary" size="md" onClick={handleClose}>
                  Close
                </Button>{" "}
                <Button type="submit" variant="primary" size="md">
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}

export default EditUser;
