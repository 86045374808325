import React from "react";
import { Header } from "../header";
// import { Footer } from "../footer";
import { Navigate } from "react-router-dom";
import { AUTH_TOKEN } from "../../../config/constants/AuthConstant";
import "./admin.styles.scss";
import { ToastContainer } from "react-toastify";
import { Sidebar } from "../sidebar";


export const AdminLayout = ({ children }) => {
  // localStorage.setItem("Auth", "sdcsdvsdvds");
  let Auth = localStorage.getItem(AUTH_TOKEN);
  return (
    <div className="container-fluid">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="col-md-12 d-flex">
        <Sidebar />
        <div className="col p-0">
          <Header title={children} />
          <div className='content_div'>
            {Auth ? children : <Navigate to="/" />}
          </div>
        </div>
      </div>
    </div>
  );
};
