
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './add_service.styles.scss';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ServiceManagment from "../../../services/ServiceManagment";
import SunEditorComponent from './suneditor';
export const DescriptionFAQs = ({ form, setForm, nextStepDescriptionFaq }) => {
  const [faqData, setFaqData] = useState({ question: '', answer: '' });
  const [fquestion, setFquestion] = useState({ question: '' });
  const [faqs, setFaqs] = useState([]);
  const [fquestions, setFquestions] = useState([]);
  const [showFAQForm, setShowFAQForm] = useState(false);
  const [showNewQuesionForm, setshowNewQuesionForm] = useState(false);
  const [faqIds, setFaqIds] = useState('');
  const [contents, setcontents] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFaqData({ ...faqData, [name]: value });
  };

  const handleQuestionInputChange = (e) => {
    const { name, value } = e.target;
    setFquestion({ ...fquestion, [name]: value });
  }
  const handleAddFAQ = async () => {
    if (faqData.question && faqData.answer) {
      try {
        const faqDataToSend = {
          question: faqData.question,
          answer: faqData.answer
        };
        const response = await ServiceManagment.addFAQ(faqDataToSend);
        if (response.status === 200) {
          // Store the response.id in the array
          setFaqIds([...faqIds, response.id]);
        }
      } catch (error) {
        console.error('Error adding FAQ:', error);
      }
      setFaqs([...faqs, { ...faqData }]);
      setFaqData({ question: '', answer: '' });
      setShowFAQForm(false);
    }
  };



  const handleAddQuestion = () => {
    if (fquestion.question) {
      setFquestions([...fquestions, { ...fquestion }]);
      setFquestion({ question: '' });
      setshowNewQuesionForm(false);
    }
  }


  const handleChange = (e, type) => {
    setForm({ ...form, [type]: { ...form[type], [e.target.name]: e.target.value } });
  }

  useEffect(() => {
    setForm({ ...form, "description": { ...form['description'], "faqs": faqIds } });
  }, [faqIds]);
  useEffect(() => {
    setForm({ ...form, "description": { ...form['description'], "fquestions": fquestions } });
  }, [fquestions]);
  useEffect(() => {
    setForm({ ...form, "description": { ...form['description'], "service_description": contents } });
  }, [contents]);




  const toggleFAQ = (index) => {
    const updatedFAQs = [...faqs];
    updatedFAQs[index].open = !updatedFAQs[index].open;
    setFaqs(updatedFAQs);
  };

  return (
    <>
      <Form className="ServiceForm" onSubmit={nextStepDescriptionFaq}>
        {/* Description form group */}
        <Form.Group className="form-group" controlId="exampleForm.ControlTextarea1">
          <Form.Label>
            Description <span className="required">*</span>
            <div className="description-sub-text">
              Briefly describe your Project
            </div>
          </Form.Label>
          <SunEditorComponent contents={contents} setcontents={setcontents} form={form} />

        </Form.Group>
        <div className="heading">Frequently Asked Questions</div>
        <hr style={{ background: "#C5C6C9" }} />
        <div className="sub-heading">
          Add Questions & Answers for Your Buyers
        </div>
        <Button
          type="button"
          className="add-faqs-btn mt-4"
          onClick={() => setShowFAQForm(true)}
        >
          + Add FAQ’s
        </Button>

        {/* FAQs */}
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className={`faq-question ${faq.open ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
                {index + 1}. {faq.question}
              </div>
              {faq.open && <div className="faq-answer">{faq.answer}</div>}
            </div>
          ))}
        </div>


        {/* FAQ Form */}
        {showFAQForm && (
          <div className="faq-form">
            <Form.Group controlId="faqQuestion">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={faqData.question}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="faqAnswer">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="answer"
                value={faqData.answer}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Button
              type="button"
              className="submit-faq-btn"
              onClick={handleAddFAQ}
            >
              Submit FAQ
            </Button>
          </div>
        )}
        <div className="partition-block">&nbsp;</div>
        <div className="all-requirement-text">
          Get all the Requirements you need from clients to get started
        </div>
        <div className="all-requirement-sub-text">
          Add questions to help buyers provide you with exactly what you need to
          start working on their order.
        </div>
        {/* hr line */}
        <Row className="mt-5">
          <Col>
            <div className="hr-line">&nbsp;</div>
          </Col>
          <Col md="auto">
            <div className="hr-line-text">
              Planningg Questions &nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.66669 6.2222C6.66669 5.99407 6.74244 5.63209 6.94361 5.35269C7.11215 5.11861 7.40085 4.88886 8.00003 4.88886C8.73426 4.88886 9.11567 5.27671 9.24475 5.66556C9.38071 6.07511 9.26479 6.53293 8.84029 6.81593C8.13871 7.28365 7.62193 7.73677 7.34968 8.37204C7.10944 8.93259 7.11027 9.54684 7.11105 10.1244L7.11114 10.2222H8.88892C8.88892 9.49449 8.90421 9.25784 8.98371 9.07235C9.04479 8.92984 9.19468 8.71629 9.82643 8.29513C10.9709 7.53217 11.2994 6.21221 10.932 5.10547C10.5577 3.97803 9.48801 3.11108 8.00003 3.11108C6.82143 3.11108 5.99902 3.62208 5.50089 4.31392C5.03539 4.96045 4.88892 5.70958 4.88892 6.2222H6.66669ZM8.88886 12.8889V11.1111H7.11108V12.8889H8.88886Z"
                  fill="#74767E"
                />
              </svg>
            </div>
          </Col>
          <Col>
            <div className="hr-line">&nbsp;</div>
          </Col>
        </Row>
        <div className="optional-question-text mb-4">
          These optional questions will be added for all Clients.
        </div>
        {/* Question 1 block */}
        <div className="option-question-section">
          <div className="question-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
            >
              <g clipPath="url(#clip0_21_9197)">
                <path
                  d="M8.96936 4.96041C9.19618 4.96677 9.42196 4.92758 9.63337 4.84518C9.84478 4.76277 10.0375 4.63882 10.2002 4.48064C10.3629 4.32246 10.4922 4.13327 10.5805 3.92425C10.6688 3.71524 10.7143 3.49064 10.7143 3.26374C10.7143 3.03684 10.6688 2.81224 10.5805 2.60323C10.4922 2.39421 10.3629 2.20502 10.2002 2.04684C10.0375 1.88866 9.84478 1.76471 9.63337 1.6823C9.42196 1.5999 9.19618 1.56071 8.96936 1.56707C8.52761 1.57946 8.10811 1.76365 7.80003 2.08048C7.49196 2.39732 7.3196 2.82182 7.3196 3.26374C7.3196 3.70566 7.49196 4.13016 7.80003 4.447C8.10811 4.76383 8.52761 4.94802 8.96936 4.96041ZM3.02936 4.96041C3.25618 4.96677 3.48196 4.92758 3.69337 4.84518C3.90478 4.76277 4.09752 4.63882 4.26019 4.48064C4.42287 4.32246 4.55218 4.13327 4.64047 3.92425C4.72877 3.71524 4.77427 3.49064 4.77427 3.26374C4.77427 3.03684 4.72877 2.81224 4.64047 2.60323C4.55218 2.39421 4.42287 2.20502 4.26019 2.04684C4.09752 1.88866 3.90478 1.76471 3.69337 1.6823C3.48196 1.5999 3.25618 1.56071 3.02936 1.56707C2.58761 1.57946 2.16811 1.76365 1.86003 2.08048C1.55196 2.39732 1.3796 2.82182 1.3796 3.26374C1.3796 3.70566 1.55196 4.13016 1.86003 4.447C2.16811 4.76383 2.58761 4.94802 3.02936 4.96041ZM8.96936 10.9004C9.41953 10.9004 9.85125 10.7216 10.1696 10.4033C10.4879 10.085 10.6667 9.65323 10.6667 9.20307C10.6667 8.75291 10.4879 8.32119 10.1696 8.00288C9.85125 7.68457 9.41953 7.50574 8.96936 7.50574C8.5192 7.50574 8.08748 7.68457 7.76917 8.00288C7.45086 8.32119 7.27203 8.75291 7.27203 9.20307C7.27203 9.65323 7.45086 10.085 7.76917 10.4033C8.08748 10.7216 8.5192 10.9004 8.96936 10.9004ZM3.02936 10.9004C3.47953 10.9004 3.91125 10.7216 4.22956 10.4033C4.54787 10.085 4.7267 9.65323 4.7267 9.20307C4.7267 8.75291 4.54787 8.32119 4.22956 8.00288C3.91125 7.68457 3.47953 7.50574 3.02936 7.50574C2.5792 7.50574 2.14748 7.68457 1.82917 8.00288C1.51086 8.32119 1.33203 8.75291 1.33203 9.20307C1.33203 9.65323 1.51086 10.085 1.82917 10.4033C2.14748 10.7216 2.5792 10.9004 3.02936 10.9004Z"
                  stroke="#B5B6BA"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_21_9197">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            &nbsp;&nbsp; Multiple choice
          </div>
          <div className="question">
            1. Is this order for personal use, business use, or a side project?
          </div>
          <div className="answer">
            For Business / Personal / Side Project use
          </div>
        </div>
        {/* Question 2 block */}
        <div className="option-question-section">
          <div className="question-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
            >
              <g clipPath="url(#clip0_21_9197)">
                <path
                  d="M8.96936 4.96041C9.19618 4.96677 9.42196 4.92758 9.63337 4.84518C9.84478 4.76277 10.0375 4.63882 10.2002 4.48064C10.3629 4.32246 10.4922 4.13327 10.5805 3.92425C10.6688 3.71524 10.7143 3.49064 10.7143 3.26374C10.7143 3.03684 10.6688 2.81224 10.5805 2.60323C10.4922 2.39421 10.3629 2.20502 10.2002 2.04684C10.0375 1.88866 9.84478 1.76471 9.63337 1.6823C9.42196 1.5999 9.19618 1.56071 8.96936 1.56707C8.52761 1.57946 8.10811 1.76365 7.80003 2.08048C7.49196 2.39732 7.3196 2.82182 7.3196 3.26374C7.3196 3.70566 7.49196 4.13016 7.80003 4.447C8.10811 4.76383 8.52761 4.94802 8.96936 4.96041ZM3.02936 4.96041C3.25618 4.96677 3.48196 4.92758 3.69337 4.84518C3.90478 4.76277 4.09752 4.63882 4.26019 4.48064C4.42287 4.32246 4.55218 4.13327 4.64047 3.92425C4.72877 3.71524 4.77427 3.49064 4.77427 3.26374C4.77427 3.03684 4.72877 2.81224 4.64047 2.60323C4.55218 2.39421 4.42287 2.20502 4.26019 2.04684C4.09752 1.88866 3.90478 1.76471 3.69337 1.6823C3.48196 1.5999 3.25618 1.56071 3.02936 1.56707C2.58761 1.57946 2.16811 1.76365 1.86003 2.08048C1.55196 2.39732 1.3796 2.82182 1.3796 3.26374C1.3796 3.70566 1.55196 4.13016 1.86003 4.447C2.16811 4.76383 2.58761 4.94802 3.02936 4.96041ZM8.96936 10.9004C9.41953 10.9004 9.85125 10.7216 10.1696 10.4033C10.4879 10.085 10.6667 9.65323 10.6667 9.20307C10.6667 8.75291 10.4879 8.32119 10.1696 8.00288C9.85125 7.68457 9.41953 7.50574 8.96936 7.50574C8.5192 7.50574 8.08748 7.68457 7.76917 8.00288C7.45086 8.32119 7.27203 8.75291 7.27203 9.20307C7.27203 9.65323 7.45086 10.085 7.76917 10.4033C8.08748 10.7216 8.5192 10.9004 8.96936 10.9004ZM3.02936 10.9004C3.47953 10.9004 3.91125 10.7216 4.22956 10.4033C4.54787 10.085 4.7267 9.65323 4.7267 9.20307C4.7267 8.75291 4.54787 8.32119 4.22956 8.00288C3.91125 7.68457 3.47953 7.50574 3.02936 7.50574C2.5792 7.50574 2.14748 7.68457 1.82917 8.00288C1.51086 8.32119 1.33203 8.75291 1.33203 9.20307C1.33203 9.65323 1.51086 10.085 1.82917 10.4033C2.14748 10.7216 2.5792 10.9004 3.02936 10.9004Z"
                  stroke="#B5B6BA"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_21_9197">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            &nbsp;&nbsp; Multiple choice
          </div>
          <div className="question">2. Which industry do you work in?</div>
          <div className="answer">
            3D Design, Academic Education, Academic Writing, Accounting, ...,
            Other
          </div>
        </div>
        {/* Question 3 block */}
        <div className="option-question-section">
          <div className="question-type">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
            >
              <g clipPath="url(#clip0_21_9197)">
                <path
                  d="M8.96936 4.96041C9.19618 4.96677 9.42196 4.92758 9.63337 4.84518C9.84478 4.76277 10.0375 4.63882 10.2002 4.48064C10.3629 4.32246 10.4922 4.13327 10.5805 3.92425C10.6688 3.71524 10.7143 3.49064 10.7143 3.26374C10.7143 3.03684 10.6688 2.81224 10.5805 2.60323C10.4922 2.39421 10.3629 2.20502 10.2002 2.04684C10.0375 1.88866 9.84478 1.76471 9.63337 1.6823C9.42196 1.5999 9.19618 1.56071 8.96936 1.56707C8.52761 1.57946 8.10811 1.76365 7.80003 2.08048C7.49196 2.39732 7.3196 2.82182 7.3196 3.26374C7.3196 3.70566 7.49196 4.13016 7.80003 4.447C8.10811 4.76383 8.52761 4.94802 8.96936 4.96041ZM3.02936 4.96041C3.25618 4.96677 3.48196 4.92758 3.69337 4.84518C3.90478 4.76277 4.09752 4.63882 4.26019 4.48064C4.42287 4.32246 4.55218 4.13327 4.64047 3.92425C4.72877 3.71524 4.77427 3.49064 4.77427 3.26374C4.77427 3.03684 4.72877 2.81224 4.64047 2.60323C4.55218 2.39421 4.42287 2.20502 4.26019 2.04684C4.09752 1.88866 3.90478 1.76471 3.69337 1.6823C3.48196 1.5999 3.25618 1.56071 3.02936 1.56707C2.58761 1.57946 2.16811 1.76365 1.86003 2.08048C1.55196 2.39732 1.3796 2.82182 1.3796 3.26374C1.3796 3.70566 1.55196 4.13016 1.86003 4.447C2.16811 4.76383 2.58761 4.94802 3.02936 4.96041ZM8.96936 10.9004C9.41953 10.9004 9.85125 10.7216 10.1696 10.4033C10.4879 10.085 10.6667 9.65323 10.6667 9.20307C10.6667 8.75291 10.4879 8.32119 10.1696 8.00288C9.85125 7.68457 9.41953 7.50574 8.96936 7.50574C8.5192 7.50574 8.08748 7.68457 7.76917 8.00288C7.45086 8.32119 7.27203 8.75291 7.27203 9.20307C7.27203 9.65323 7.45086 10.085 7.76917 10.4033C8.08748 10.7216 8.5192 10.9004 8.96936 10.9004ZM3.02936 10.9004C3.47953 10.9004 3.91125 10.7216 4.22956 10.4033C4.54787 10.085 4.7267 9.65323 4.7267 9.20307C4.7267 8.75291 4.54787 8.32119 4.22956 8.00288C3.91125 7.68457 3.47953 7.50574 3.02936 7.50574C2.5792 7.50574 2.14748 7.68457 1.82917 8.00288C1.51086 8.32119 1.33203 8.75291 1.33203 9.20307C1.33203 9.65323 1.51086 10.085 1.82917 10.4033C2.14748 10.7216 2.5792 10.9004 3.02936 10.9004Z"
                  stroke="#B5B6BA"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_21_9197">
                  <rect
                    width="12"
                    height="12"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            &nbsp;&nbsp; Multiple choice
          </div>
          <div className="question">
            3. What are you looking to achieve with this order?
          </div>
          <div className="answer">
            Build a mobile app, Build a website, Create an animation, Develop a
            game, ..., Other
          </div>
        </div>

        {/* hr line */}
        <Row className="mt-5">
          <Col>
            <div className="hr-line">&nbsp;</div>
          </Col>
          <Col md="auto">
            <div className="hr-line-text">Your Questions </div>
          </Col>
          <Col>
            <div className="hr-line">&nbsp;</div>
          </Col>
        </Row>
        <div className="your-question-text">
          Here’s where you can request any details needed to complete the order.
          There’s no need to repeat any of the general questions asked above by
          Fiverr.
        </div>
        <Button type="button" className="add-faqs-btn mt-4"
          onClick={() => setshowNewQuesionForm(true)}
        >
          + Add New Question

        </Button>
        <div className="faq-list">
          {fquestions.map((faq, index) => (
            <div key={index} className="faq-item">
              <div className="faq-question" >
                {index + 1}. {faq.question}
              </div>
              {faq.open && <div className="faq-answer">{faq.answer}</div>}
            </div>
          ))}
        </div>

        {showNewQuesionForm && (
          <div className="faq-form">
            <Form.Group controlId="faqQuestion">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                name="question"
                value={fquestion.question}
                onChange={handleQuestionInputChange}
              />
            </Form.Group>
            <Button
              type="button"
              className="submit-faq-btn"
              onClick={handleAddQuestion}
            >
              Submit FAQ
            </Button>
          </div>
        )}
        <div className="d-grid gap-2 mb-3 mt-5 description-FAQs-btn-block">
          <Button type='submit' className="themeBtn" size="lg">
            Continue
          </Button>
        </div>
      </Form>
    </>
  );
};
