import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Tabs from "react-bootstrap/Tabs";
import "./add_service.styles.scss";
import IconHeart from "../../../assets/images/admin/icon _heart_.png";
import IconStar from "../../../assets/images/admin/icon _star 1_.png";
import ActiveStar from "../../../assets/images/activestar.png";
import inactiveheart from "../../../assets/images/inactiveheart.png";
// import IconCompleteness from "../../../assets/images/admin/completeness.png";
// import IconServicePreviewImage from "../../../assets/images/admin/service-preview-image.png";
// import IconBlueI from "../../../assets/images/admin/blue-i.png";
import { BasicInfo } from "./BasicInfo";
import SunEditorComponent from "./suneditor.js";
import ServiceManagment from "../../../services/ServiceManagment";
import { ShowErrorMessage, ShowSuccessMessage } from "../../../components/Notification";
import ProgressBar from "react-bootstrap/ProgressBar";
import { DescriptionFAQs } from "./DesciptionFaq";
import { Amenities } from "./Amenities";
import { SideReview } from "./SideReview";
import { Pricing } from "./Pricing";
import { Photos } from "./Photos";

export const AddService = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const data = location?.state?.ServiceData
  const [loading, setLoading] = useState(false);
  const [serviceId, setServiceId] = useState();
  const [starActive, setStarActive] = useState(false);
  const [heartActive, setHeartActive] = useState(false);
  const [key, setKey] = useState("home");
  const userId = localStorage.getItem('userId');
  const [uploadForm, setUploadForm] = useState({
    "basic": {},
    "description": {},
    "amenties": {},
    "pricing": {},
    "photos": {}
  })

  const nextStepBasicInfo = (e) => {
    e.preventDefault();
    // Call the nextStep function passed from the parent component
    setKey("description_faqs");

  }
  const nextStepDescriptionFaq = (e) => {
    e.preventDefault();
    setKey("amenities");
  }
  const nextStepAmenity = (e) => {
    e.preventDefault();
    setKey("pricing");
  }
  const nextStepPricing = (e) => {
    e.preventDefault();
    setKey("photos");
  }
  const handleStarClick = () => {
    setStarActive(!starActive); // Toggle the active state
    setHeartActive(false); // Make heart inactive
  };

  const handleHeartClick = () => {
    setHeartActive(!heartActive); // Toggle the active state
    setStarActive(false); // Make star inactive
    // You can also store the value related to the heart here
  };

  const amenitiesData = uploadForm.amenties?.amenity_type?.map(amenity => amenity.name);
  const submitServiceForm = async () => {
    if (uploadForm.photos.service_images) {
      try {
        setLoading(true);
        const formData = new FormData();
        uploadForm.photos.service_images.forEach((file, index) => {
          formData.append('photos[]', file);
        });
        const response = await ServiceManagment.fileUpload(formData);
        if (response.status === 200) {
          let finalFormData = {
            projectName: uploadForm.basic?.project_name,
            projectCategory: uploadForm.basic?.project_category,
            projectBuildingType: uploadForm.basic?.building_type,
            projectDesignType: uploadForm.basic?.design_type,
            roomType: uploadForm.basic?.roomtype_category || null,
            plotAreaFrom: uploadForm.basic?.plot_Areatype,
            plotAreaUpto: 'NULL',
            bhk: uploadForm.basic?.bhk_type,
            coveredArea: uploadForm.basic?.covered_area,
            plotSizeLength: uploadForm.basic?.plot_size_length,
            plotSizeWidth: uploadForm.basic?.plot_size_width,
            plotMainFacing: uploadForm.basic?.facing,
            plotOtherFacing: uploadForm.basic?.other_facing,
            noOfFloors: uploadForm.basic?.floor_type,
            noOfBedrooms: uploadForm.basic?.bedroom_type,
            noOfKitchens: uploadForm.basic?.kitchen_type,
            nofBathrooms: uploadForm.basic?.bathroom_type,
            stairLocations: uploadForm.basic?.stair_type,
            parking: 'Null',
            description: uploadForm.description?.service_description,
            otherAnimities: amenitiesData,
            vastuCompliances: uploadForm.amenties?.vastu_compliance,
            preferredFor: uploadForm.amenties?.preferred_category,
            pricingId: uploadForm.pricing?.packageId,
            photos: response.data,
            faq: uploadForm.description?.faqs || null,
            createdBy: userId,
          }
          try {
            const response = await ServiceManagment.createService(finalFormData, serviceId);
            if (response.status === 200) {
              navigate('/service-listing');
              ShowSuccessMessage(response.message);
            } else if (response.status === 403) {
              const errors = response.errors;
              for (const fieldName in errors) {
                const errorMessage = errors[fieldName][0];
                ShowErrorMessage(errorMessage);
              }
            } else {
              ShowErrorMessage(response.message.description);
            }
          } catch (error) {
          }
        }
      } catch (error) {
        console.error('Error uploading images:', error);
      }
      finally {
        setLoading(false);
      }
    }

  }
  const updateServiceForm = async () => {
    let mergedImages = [];
    if (uploadForm.photos.service_images) {
      try {
        setLoading(true);
        const formData = new FormData();
        uploadForm.photos.service_images.forEach((file, index) => {
          formData.append('photos[]', file);
        });
        const response = await ServiceManagment.fileUpload(formData);
        mergedImages = [...response.data, ...uploadForm.photos.old_images];
      } catch (error) {
        // Handle any errors that occur during the process
        console.error(error);
      }
    } else {
      mergedImages = [...uploadForm.photos.old_images];
    }
    let finalFormData = {
      projectName: uploadForm.basic?.project_name,
      projectCategory: uploadForm.basic?.project_category,
      projectBuildingType: uploadForm.basic?.building_type,
      projectDesignType: uploadForm.basic?.design_type,
      roomType: uploadForm.basic?.roomtype_category || null,
      plotAreaFrom: uploadForm.basic?.plot_Areatype,
      plotAreaUpto: 'NULL',
      bhk: uploadForm.basic?.bhk_type,
      coveredArea: uploadForm.basic?.covered_area,
      plotSizeLength: uploadForm.basic?.plot_size_length,
      plotSizeWidth: uploadForm.basic?.plot_size_width,
      plotMainFacing: uploadForm.basic?.facing,
      plotOtherFacing: uploadForm.basic?.other_facing,
      noOfFloors: uploadForm.basic?.floor_type,
      noOfBedrooms: uploadForm.basic?.bedroom_type,
      noOfKitchens: uploadForm.basic?.kitchen_type,
      nofBathrooms: uploadForm.basic?.bathroom_type,
      stairLocations: uploadForm.basic?.stair_type,
      description: uploadForm.description?.service_description,
      otherAnimities: amenitiesData,
      vastuCompliances: uploadForm.amenties?.vastu_compliance,
      preferredFor: uploadForm.amenties?.preferred_category,
      pricingId: uploadForm.pricing?.packageID,
      photos: mergedImages,
      parking: 'Null',
      faq: uploadForm.description?.faqs || null,
    }
    try {
      const response = await ServiceManagment.updateService(finalFormData, serviceId);
      if (response.status === 200) {
        navigate('/service-listing');
        ShowSuccessMessage(response.message);
        setLoading(true);

      } else if (response.status === 403) {
        const errors = response.errors;
        for (const fieldName in errors) {
          const errorMessage = errors[fieldName][0];
          ShowErrorMessage(errorMessage);
        }
      } else {
        ShowErrorMessage(response.message.description);
      }
    } catch (error) {
    }

  }


  useEffect(() => {
    if (data) {
      setServiceId(data?.id);
      const otherAmenities = JSON.parse(data.other_animities);
      const Amenities = otherAmenities.map((item) => ({ name: item, value: item }));
      let plotAreaType = data?.plot_area_from;
      if (data?.plot_area_upto !== null && data?.plot_area_upto !== 'NULL') {
        plotAreaType += `-${data?.plot_area_upto}`;
      } else {
        plotAreaType = plotAreaType || '';
      }
      const faqData = JSON.parse(data?.faq);


      setUploadForm({
        ...uploadForm,
        "basic": {
          project_name: data?.project_name,
          project_category: data?.project_category,
          building_type: data?.project_building_type,
          extended_building_type: data?.project_building_type_extended,
          design_type: data?.project_design_type,
          roomtype_category: data?.room_type,
          bhk_type: data?.bhk,
          covered_area: data?.covered_area,
          plot_size_length: data?.plot_size_length,
          plot_size_width: data?.plot_size_width,
          plot_Areatype: plotAreaType,
          facing: data?.plot_main_facing,
          other_facing: data?.plot_other_facing,
          floor_type: data?.no_of_floors,
          bedroom_type: data?.no_of_bedrooms,
          bathroom_type: data?.no_of_bathrooms,
          kitchen_type: data?.no_of_kitchens,
          stair_type: data?.stair_locations,



        },
        "description": {
          service_description: data?.description,
          faqs: faqData,

        },
        "amenties": {
          amenity_type: Amenities,
          vastu_compliance: data.vastu_compliances,
          preferred_category: data.preferred_for,

        },
        "pricing": {
          pricingData: data?.package_data,

        },
        "photos": {
          old_images: JSON.parse(data?.photos),

        }
      });
    }
  }, [data]);
  return (
    <div>
      <div className="">

      </div>
      <Container className="px-4">
        <Row className="gx-4  gy-4">
          <Col sm={8}>
            <div className="p-4 upload-listing">
              <Row>
                <Col sm={8}>
                  <div className="page-title">Upload Listing</div>
                </Col>
                <Col sm={4}>
                  <div className="mandatory-fields-text">
                    <span className="required">*</span> indicates a mandatory
                    field
                  </div>
                </Col>
              </Row>

              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 tabs"
              >
                <Tab eventKey="home" title="Basic Info">
                  <Row>
                    <Col className="">
                      <div className="float-end">
                        <img className="social-icon" 
                          src={starActive ? ActiveStar : IconStar}
                          alt="Star"
                          onClick={handleStarClick}
                        />
                        <img className="social-icon"
                          src={heartActive ? IconHeart : inactiveheart}
                          alt="Heart"
                          onClick={handleHeartClick}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div className="clearFix"></div>
                  <BasicInfo setForm={setUploadForm} form={uploadForm} nextStepBasicInfo={nextStepBasicInfo} />
                </Tab>
                <Tab eventKey="description_faqs" title="Description and FAQs">
                  <DescriptionFAQs setForm={setUploadForm} form={uploadForm} nextStepDescriptionFaq={nextStepDescriptionFaq} />
                </Tab>
                <Tab eventKey="amenities" title="Amenities">
                  <Amenities setForm={setUploadForm} form={uploadForm} nextStepAmenity={nextStepAmenity} />
                </Tab>
                <Tab eventKey="pricing" title="Pricing">
                  <Pricing nextStepPricing={nextStepPricing} setForm={setUploadForm} form={uploadForm} />
                </Tab>
                <Tab eventKey="photos" title="Photos">
                  <Photos setForm={setUploadForm} form={uploadForm} submitServiceFormFunction={submitServiceForm} updateServiceFormFunction={updateServiceForm} loading={loading} setLoading={setLoading} data={data} />
                </Tab>
              </Tabs>
            </div>
          </Col>
          <SideReview uploadForm={uploadForm} />
        </Row>
      </Container>
    </div>
  );
};

export default AddService;



