import { Link } from 'react-router-dom';
import styles from './header.module.scss'
import { GrNotification } from 'react-icons/gr';
import search from "../../../assets/images/search.png";
import notify from "../../../assets/images/notifyicon.png";
import help from "../../../assets/images/help.png";

export const Header = ({ title }) => {
  return (
    <div className={styles.header_main}>
      <div className='d-flex align-items-center justify-content-between gap-2'>
        <div className={styles.search}>
          <input type='text' placeholder='Search...'></input>
          <span><img src={search} alt="saerch" /></span>
        </div>
        <div className='d-flex align-items-center gap-2'>
          {/* <GrNotification style={{ fontSize: "20px" }} /> */}

          <img src={help} alt="help" />


          <button type="button" class={styles.icon_button}>
            <img src={notify} alt="notify" />
            <span class={styles.iconbutton__badge}>2</span>
          </button>

          <img
            src="https://github.com/mdo.png"
            alt="hugenerd"
            width={30}
            height={30}
            className="rounded-circle"
          />
          <Link to='/logout' className="d-none d-sm-inline mx-1">Logout</Link>
        </div>
      </div>
    </div>
  );
};
