import React, { useEffect, useState } from 'react'
import styles from './packages.module.scss'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PackageBox } from './PackageBox'
import { SideFilter } from './SideFilter'
import { AddPackage } from './AddPackage'
import ServiceManagment from "../../../services/ServiceManagment";
import { TtileDetails } from './TtileDetails'
import { async } from 'q'
import PackageManagment from '../../../services/PackageManagment'
import { ShowErrorMessage, ShowSuccessMessage } from '../../Notification'

export const Packages = () => {
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState('');
    const [selectedPackageStatus, setselectedPackageStatus] = useState('Active');
    const [packageData, setPackageData] = useState([]);
    const [isAddPackageVisible, setIsAddPackageVisible] = useState(false);
    const [editedPackageData, setEditedPackageData] = useState(null); // State to store the edited package data
    const [selectedPackageName, setSelectedPackageName] = useState(null);

    const [projectTypes, SetProjectTypes] = useState([]);
    const [serviceId, setService] = useState('');

    const fetchAllProjectCategory = async () => {
        try {
            const response = await ServiceManagment.getProjectCategory();
            if (response.status === 200) {
                SetProjectTypes(response.data);
            }
        } catch (error) {
            console.error('Error fetching project categories:', error);
        }
    };
    const deletePackage = async () => {
        try {
            if (packageData.length > 0) {
                const packageIds = packageData.map((pkg) => pkg.id);
                const response = await PackageManagment.deletePackage({ packageIds });
                if (response.status === 200) {
                    ShowSuccessMessage(response.message);
                } else {
                    ShowErrorMessage('Error deleting packages');
                }
            } else {
                console.log('No packages to delete.');
            }
        } catch (error) {
            console.error('Error deleting packages:', error);
        }
    };




    useEffect(() => {
        fetchAllProjectCategory();
    }, []);
    const handleChange = (event) => {
        setService(event.target.value);
    };

    const showAddPackage = () => {
        setIsAddPackageVisible(true);
    }

    // Function to hide the AddPackage component
    const hideAddPackage = () => {
        setIsAddPackageVisible(false);
    }
    const handleEditPackage = (packageData) => {
        setEditedPackageData(packageData);
        setIsAddPackageVisible(true); // Show the Add Package form
    };


    return (
        <div className={`${styles.PackageListingMain} row`}>
            <div className='col-12 mt-5 d-flex'>
                <div className='col-8'>
                    <h1>Package Details</h1>
                    <p>Welcome back to Planningg.homes Dashboard</p>
                </div>
                <div className='col-4'>
                    {packageData.length && !isAddPackageVisible ? (
                        <>
                            <button className={styles.deletebtn} onClick={deletePackage}>Delete Package</button>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                    {isAddPackageVisible ? (
                        <Link to='/packages'><button className={styles.addbtn} onClick={() => window.location.reload(false)}>Back to Show Package</button></Link>
                    ) : (
                        <button className={styles.addbtn} onClick={showAddPackage}>+ Add Package</button>
                    )}
                </div>
            </div>
            <TtileDetails selectedPackageName={selectedPackageName} selectedPackageStatus={selectedPackageStatus} handleChange={handleChange} projectTypes={projectTypes} packageData={packageData} isAddPackageVisible={isAddPackageVisible} editedPackageData={editedPackageData} />
            <div className='col-12 d-flex  gap-2'>
                {isAddPackageVisible ? (
                    <AddPackage
                        editedPackageData={editedPackageData}
                        handleEditPackage={handleEditPackage}
                        serviceId={serviceId}
                    />
                ) : (
                    <>
                        <PackageBox
                            selectedValue={selectedValue}
                            selectedPackage={selectedPackage}
                            selectedPackageStatus={selectedPackageStatus}
                            packageData={packageData}
                            setPackageData={setPackageData}
                            handleEditPackage={handleEditPackage}
                        />
                    </>
                )}
                <SideFilter selectedPackage={selectedPackage} setSelectedPackage={setSelectedPackage} selectedPackageStatus={selectedPackageStatus} setSelectedPackageName={setSelectedPackageName} setselectedPackageStatus={setselectedPackageStatus} />
            </div>
        </div>
    )
}
