import React, { useState } from 'react';
import styles from "./sample.module.scss"   
import DawnloadSvg from "../../../assets/images/svg/file_uplaod.svg"
import SampleManagment from '../../../services/SampleManagment'
import { ShowErrorMessage, ShowSuccessMessage } from '../../Notification'


export const  SampleUplaod = () => {

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      ShowErrorMessage('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('sampleFile', selectedFile);
    try {
      const response = await SampleManagment.uploadSample(formData);
      if (response.status === 200) {
        ShowSuccessMessage(response.message);
        setSelectedFile(null);
      } else {
        ShowErrorMessage(response.message);
      }
    } catch (error) {
      ShowErrorMessage('An error occurred while uploading the file.');
    }
  };
    return (
      <div className={styles.app}>
      <div className={styles.parent}>
        <div className={styles.file_upload}>
          <img src={DawnloadSvg} alt="" />
          <h3>Click box to upload</h3>
          <p>Maximum file size 10MB</p>
          <input type="file" name="sampleFile" onChange={handleFileChange} />
        </div>
         
        {selectedFile && <p>Selected file: {selectedFile.name}</p>}
          <button onClick={handleSubmit} className={styles.uploadButton}>Upload</button>
      </div>
    </div>
  );
}
