import { useState } from "react";
import Logo from "../../assets/images/transparent-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../index.styles.scss";
import { Link } from "react-router-dom";
import {
  ShowSuccessMessage,
  ShowErrorMessage,
} from "../../components/Notification";
import AuthService from "../../services/AuthService";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <>
      <div className="login_main_page w-100 d-flex">
        <div className="row w-100">
          <div className="col-md-5 left_login_page">
            <img src={Logo} alt="" height="" width="123px" />
            <div className="login-center-content">
              <label>We're glad to see you again!</label>
              <h2>Welcome to Admin Portal</h2>
            </div>
          </div>
          <div className="col-md-7 col-12 right_login_page d-flex justify-content-center align-items-center">
            {otpSent === false ? (
              <>
                <ForgotPasswordComponent
                  setOtpSent={setOtpSent}
                  email={email}
                  setEmail={setEmail}
                />
              </>
            ) : (
              <>
                <ResetPasswordComponent
                  setOtpSent={setOtpSent}
                  email={email}
                  setEmail={setEmail}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export const ForgotPasswordComponent = ({ setOtpSent, email, setEmail }) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return false;
    }

    event.preventDefault();
    event.stopPropagation();
    const response = await AuthService.ForgotPassword({ email });
    if (response.success === false) {
      ShowErrorMessage(response.message);
      return false;
    }
    console.log(response?.data?.otp);
    ShowSuccessMessage("We have sent a OTP at your email address !");
    setTimeout(() => {
      setOtpSent(true);
    }, 500);
  };

  return (
    <>
      <div className="form-section">
        <h1 className="form-title">Forgot Password</h1>
        <div className="d-flex gap-3 align-items-center">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control"
              />
              <Link to="/" className="float-end link">
                {" "}
                Back to Login?
              </Link>
              <Form.Control.Feedback type="invalid">
                Email address is required.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="clearfix"></div>
            <div className="d-grid gap-2 mb-3 mt-5 ">
              <Button type="submit" className="themeBtn" size="lg">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export const ResetPasswordComponent = ({ setOtpSent, email, setEmail }) => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [otp, setOtp] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return false;
    }

    event.preventDefault();
    event.stopPropagation();
    const response = await AuthService.ResetPassword({
      email: email,
      otp: otp,
      password: password,
      cpassword: cPassword,
    });
    if (response.success === false) {
      ShowErrorMessage(response.message);
      return false;
    }
    ShowSuccessMessage(response.message);
    setTimeout(() => {
      navigate("/");
    }, 2000);
  };

  return (
    <>
      <div className="form-section">
        <h1 className="form-title">Reset Password</h1>
        <div className="d-flex gap-3 align-items-center">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="otp">
              <Form.Label>OTP</Form.Label>
              <Form.Control
                type="text"
                required
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />

              <Form.Control.Feedback type="invalid">
                OTP is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />

              <Form.Control.Feedback type="invalid">
                Password is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="cpassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                required
                value={cPassword}
                onChange={(e) => {
                  setCPassword(e.target.value);
                }}
              />
              <Link to="/" className="float-end link">
                {" "}
                Back to Login?
              </Link>
              <Form.Control.Feedback type="invalid">
                Confirm Password is required.
              </Form.Control.Feedback>
            </Form.Group>
            <div className="clearfix"></div>
            <div className="d-grid gap-2 mb-3 mt-5 ">
              <Button type="submit" className="themeBtn" size="lg">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
