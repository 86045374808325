import React from 'react'
import styles from './packages.module.scss'
import CorrectSVg from '../../../assets/images/svg/icon-check-circle-green.svg.svg'
import IconShield from '../../../assets/images/svg/icon-shield-check.svg.svg'
import { Link } from 'react-router-dom'
import PackageManagment from '../../../services/PackageManagment'
import { ShowErrorMessage, ShowSuccessMessage } from '../../Notification'
import { useEffect } from 'react'
import { useState } from 'react'

export const PackageBox = ({ selectedValue, selectedPackage, selectedPackageStatus, packageData, setPackageData, handleEditPackage }) => {

  const getPackageDetails = async () => {
    if (selectedPackage && selectedPackageStatus) {
      try {
        const response = await PackageManagment.getPackageDetails(selectedPackage, selectedPackageStatus);
        if (response.status === 200) {
          setPackageData(response?.data);
        } else {
          ShowErrorMessage(response.message);
        }
      } catch (error) {
        ShowErrorMessage(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    getPackageDetails();
  }, [selectedPackage, selectedPackageStatus]);
  useEffect(() => {
    console.log(selectedPackage, 'herererre');
    console.log(selectedPackageStatus, 'ddddddddddddddddddd');
  }, [selectedPackage, selectedPackageStatus]);
  return (
    <div className="col-8 ">
      <div className='d-flex gap-4 mt-5'>
        {packageData.map((data, index) => (
          <div className={`${styles.box_design}`}>
            <div className={styles.overlap_group}>
              <div className={styles.boxFirstpart}>
                <div className={styles.text_wrapper}>{data?.package_name}</div>
                <div className={styles.boxsexondPart}>
                  <div className={styles.text_wrapper2}>{data?.package_pricing}</div>
                  <div className={styles.text_wrapper3}>{data?.package_discounted_pricing}</div>
                </div>
              </div>
              <div className={styles.middleContentBox}>
                <p className={styles.what_you_get_with}>
                  <span className={styles.text_wrapper}>What you get with </span>
                  <span className={styles.span} span>Trial</span>
                  <span className={styles.text_wrapper}>:</span>
                </p>
                <div className={styles.batchBoxMain}>
                  {data?.packages_details_points && JSON.parse(data.packages_details_points).map((point, index) => (
                    <div className={styles.batchBox}>
                      <ul>
                        <li>  <img className={styles.img} alt="Icon check circle" src={CorrectSVg} />
                          <div key={index} className={styles.text_wrapper_2}>{Object.values(point)[0]}</div></li>
                      </ul>

                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {packageData.length ? (
        <div className={styles.lowerPartBox}>
          <div className={styles.editButton} onClick={() => handleEditPackage(packageData)}>
            <div className={styles.text_wrapper}>Edit Package</div>
          </div>
        </div>
      ) : (
        <div className={styles.lowerPartBox}>
          <div className={styles.editButton}>
            <div className={styles.text_wrapper}>No package Found</div>
          </div>
        </div>
      )}

    </div>


  )
}
