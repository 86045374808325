import { Route, Routes } from "react-router-dom";
import routes from "../../config/constants/routeConstant";
import { LoginRegister } from "../../auth/login";
import { Logout } from "../../auth/logout";
import { ForgotPassword } from "../../auth/forgot-password";
import { PublicLayout } from "../layout/Public";

import { AdminLayout } from "../layout/Admin";
import { Dashboard } from "../views/dashboard/dashboard";
import { MembersManagement } from "../views/users-management/members";
import { UsersManagement } from "../views/users-management/users";
import { AddService } from "../views/service-management";
import { EditService } from "../views/service-management/EditService";
import { Packages } from "../views/packages";
import { AddPackage } from "../views/packages/AddPackage";
// import { EditPackage } from "../views/packages/EditPackage";
import { OrderManagment } from "../views/order";
import { ListingMain } from "../views/lisitng";
import { CalendarView } from "../views/Calendar";
import { Leads } from "../views/Leads";
import { SampleUplaod } from "../views/Sample";
// import { AddUser } from "../../views/app-views/users-management/add_user";

export const Navigation = () => {
  return (
    <Routes>
      <Route
        path={routes.loginRoute}
        element={
          <PublicLayout>
            <LoginRegister />
          </PublicLayout>
        }
      />

      <Route
        path={routes.forgotPasswordRoute}
        element={
          <PublicLayout>
            <ForgotPassword />
          </PublicLayout>
        }
      />
      <Route
        path={routes.dashboardRoute}
        element={
          <AdminLayout>
            <Dashboard />
            {/* <UsersManagement /> */}
          </AdminLayout>
        }
      />
      <Route
        path={routes.PackagesRoute}
        element={
          <AdminLayout>
            <Packages />
            {/* <UsersManagement /> */}
          </AdminLayout>
        }
      />
          <Route
        path={routes.AddPackage}
        element={
          <AdminLayout>
            <AddPackage />
            {/* <UsersManagement /> */}
          </AdminLayout>
        }
      />
      
         <Route
        path={routes.CalendarRoute}
        element={
          <AdminLayout>
            <CalendarView />
         
          </AdminLayout>
        }
      />
               <Route
        path={routes.LeadsRoute}
        element={
          <AdminLayout>
            <Leads />
         
          </AdminLayout>
        }
      />
      <Route
        path={routes.ServiceListing}
        element={
          <AdminLayout>
            <ListingMain />
            {/* <UsersManagement /> */}
          </AdminLayout>
        }
      />
            <Route
        path={routes.SampleUplaod}
        element={
          <AdminLayout>
            <SampleUplaod />
            {/* <UsersManagement /> */}
          </AdminLayout>
        }
      />
      <Route
        path={routes.logoutRoute}
        element={
          <AdminLayout>
            <Logout />
          </AdminLayout>
        }
      />
      <Route
        path={routes.ManageOrdersRoute}
        element={
          <AdminLayout>
            <OrderManagment />
          </AdminLayout>
        }
      />
      <Route
        path={routes.membersMangementRoute}
        element={
          <AdminLayout>
            <MembersManagement />
          </AdminLayout>
        }
      />
      <Route
        path={routes.userMangementRoute}
        element={
          <AdminLayout>
            <UsersManagement />
          </AdminLayout>
        }
      />
      <Route
        path={routes.serviceMangementRoute}
        element={
          <AdminLayout>
            <AddService />
          </AdminLayout>
        }
      />
           <Route
        path={routes.EditServicesRoute}
        element={
          <AdminLayout>
            <EditService />
          </AdminLayout>
        }
      />
    </Routes>
  );
};
