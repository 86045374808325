import React, { useState } from 'react';
import styles from './add_service.styles.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Star from '../../../assets/images/star.png';
import Like from '../../../assets/images/like.png';
import Edit from '../../../assets/images/edit.png';
import Dollor from '../../../assets/images/dollor.png';
import ServiceManagment from '../../../services/ServiceManagment';
import { ShowSuccessMessage } from '../../Notification';
import { ButtonLoader } from '../../loader/ButtonLoader';
import { useEffect } from 'react';
export const Pricing = ({ form, setForm, nextStepPricing }) => {
  const [loading, setLoading] = useState(false);
  const [pricingIds, setPricingIds] = useState([]);
  const [pricingFormData, setPricingFormData] = useState({
    basic: {
      name: '',
      details: '',
      deliveryTime: '',
      select: '',
      floorPlans: true,
      floorPlans: false,
      furnitureLayout: false,
      elevation3D: false,
      elevation2D: false,
      workingDrawing: false,
      mepDrawing: false,
      boqEstimate: false,
      designOption: false,
      price: '',
    },
    standard: {
      name: '',
      details: '',
      deliveryTime: '',
      select: '',
      floorPlans: true,
      floorPlans: false,
      furnitureLayout: false,
      elevation3D: false,
      elevation2D: false,
      workingDrawing: false,
      mepDrawing: false,
      boqEstimate: false,
      designOption: false,
      price: '',
    },
    premium: {
      name: '',
      details: '',
      deliveryTime: '',
      select: '',
      floorPlans: true,
      floorPlans: false,
      furnitureLayout: false,
      elevation3D: false,
      elevation2D: false,
      workingDrawing: false,
      mepDrawing: false,
      boqEstimate: false,
      designOption: false,
      price: '',
    },
  });
  const updatedPricingFormData = form?.pricing?.pricingData?.map(item => {
    let withpackageData = JSON?.parse(item.with_package);
    let deliveringData = JSON?.parse(item?.package_revision);
    return {
      [item.package_types]: {
        name: item.package_name || '',
        details: item.details_offering_packages || '',
        deliveryTime: deliveringData?.deliveryTime || '',
        select: deliveringData?.select || '',
        floorPlans: withpackageData?.floorPlans || false,
        furnitureLayout: withpackageData?.furnitureLayout || false,
        elevation3D: withpackageData?.elevation3D || false,
        elevation2D: withpackageData?.elevation2D || false,
        workingDrawing: withpackageData.workingDrawing || false,
        mepDrawing: withpackageData.mepDrawing || false,
        boqEstimate: withpackageData.boqEstimate || false,
        designOption: withpackageData.designOption || false,
        price: item.package_pricing || '',
        pricingIds: item.id || '',
      },
    };
  });
  useEffect(() => {
    if (updatedPricingFormData) {
      const newPricingFormData = updatedPricingFormData.reduce((acc, val) => ({ ...acc, ...val }), {});
      setPricingFormData(newPricingFormData);
      const ids = form?.pricing?.pricingData?.map((item) => item.id);
      setPricingIds(ids);
    }
  }, [form?.pricing?.pricingData]);



  const handleInputChange = (category, field, value) => {
    setPricingFormData((prevData) => ({
      ...prevData,
      [category]: {
        ...prevData[category],
        [field]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    console.log(pricingFormData?.basic?.floorPlans, 'ddddddgggggggggg');
    e.preventDefault();
    try {
      setLoading(true); // Set loading to true when the button is clicked
      const response = await ServiceManagment.submitPricing(pricingFormData);
      if (response.status === 200) {
        ShowSuccessMessage(response.message);
        setForm({ ...form, pricing: { packageId: response?.ids } });
        nextStepPricing(e);
      }
    } catch (error) {
      console.error('Error uploading pricing:', error);
    } finally {
      setLoading(false); // Set loading back to false after the API call is completed
    }
  };
  return (
    <div className="frame">
      <Form onSubmit={(e) => handleSubmit(e)}>
        {/* <div className="d-flex w-100 mb-2 justify-content-end">
          <img src={Star} alt="" width="" height="" />
          <img className="ms-2" src={Like} alt="" width="" height="" />
        </div> */}
        <h3 className="mb-4">Packages<span className="star">*</span></h3>
        <div className="w-100">
          <table className="w-100 tablebox">
            <thead>
              <tr>
                <th>Details in packages (Editable) <img src={Edit} alt="" width="" height="" /> </th>
                <th>Basic</th>
                <th>Standard</th>
                <th>Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2}></td>
                <td className="name">
                  Name your package
                  <input
                    className="package-input-box"
                    value={pricingFormData.basic.name}
                    onChange={(e) => handleInputChange('basic', 'name', e.target.value)}
                  />
                </td>
                <td className="name">
                  Name your package
                  <input
                    className="package-input-box"
                    value={pricingFormData.standard.name}
                    onChange={(e) => handleInputChange('standard', 'name', e.target.value)}
                  />
                </td>
                <td className="name">
                  Name your package
                  <input
                    className="package-input-box"
                    value={pricingFormData.premium.name}
                    onChange={(e) => handleInputChange('premium', 'name', e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td className="bg-white name">
                  Describe the details of your offerings
                  <input
                    className="package-input-box"
                    value={pricingFormData.basic.details}
                    onChange={(e) => handleInputChange('basic', 'details', e.target.value)}
                  />
                </td>
                <td className="name">
                  Describe the details of your offerings
                  <input
                    className="package-input-box"
                    value={pricingFormData.standard.details}
                    onChange={(e) => handleInputChange('standard', 'details', e.target.value)}
                  />
                </td>
                <td className="name">
                  Describe the details of your offerings
                  <input
                    className="package-input-box"
                    value={pricingFormData.premium.details}
                    onChange={(e) => handleInputChange('premium', 'details', e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td rowSpan={2}>Revisions</td>
                <td>
                  <select
                    className="table-dropdown"
                    value={pricingFormData.basic.deliveryTime}
                    onChange={(e) => handleInputChange('basic', 'deliveryTime', e.target.value)}
                  >
                    <option selected>Delivery Time</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </td>
                <td>
                  <select
                    className="table-dropdown"
                    value={pricingFormData.standard.deliveryTime}
                    onChange={(e) => handleInputChange('standard', 'deliveryTime', e.target.value)}
                  >
                    <option selected>Delivery Time</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </td>
                <td>
                  <select
                    className="table-dropdown"
                    value={pricingFormData.premium.deliveryTime}
                    onChange={(e) => handleInputChange('premium', 'deliveryTime', e.target.value)}
                  >
                    <option selected>Delivery Time</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="bg-white">
                  <select
                    className="table-dropdown"
                    value={pricingFormData.basic.select}
                    onChange={(e) => handleInputChange('basic', 'select', e.target.value)}
                  >
                    <option selected>Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </td>
                <td>
                  <select
                    className="table-dropdown"
                    value={pricingFormData.standard.select}
                    onChange={(e) => handleInputChange('standard', 'select', e.target.value)}
                  >
                    <option selected>Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </td>
                <td>
                  <select
                    className="table-dropdown"
                    value={pricingFormData.premium.select}
                    onChange={(e) => handleInputChange('premium', 'select', e.target.value)}
                  >
                    <option selected>Select</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </td>
              </tr>{/* Continue adding more rows and input fields as needed */}
              <tr>
                <td>Floor Plans</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.floorPlans}
                    onChange={(e) => handleInputChange('basic', 'floorPlans', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.floorPlans}
                    onChange={(e) => handleInputChange('standard', 'floorPlans', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.floorPlans}
                    onChange={(e) => handleInputChange('premium', 'floorPlans', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td>Furniture Layout</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.furnitureLayout}
                    onChange={(e) => handleInputChange('basic', 'furnitureLayout', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.furnitureLayout}
                    onChange={(e) => handleInputChange('standard', 'furnitureLayout', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.furnitureLayout}
                    onChange={(e) => handleInputChange('premium', 'furnitureLayout', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td>3D Front Elevation</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.elevation3D}
                    onChange={(e) => handleInputChange('basic', 'elevation3D', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.elevation3D}
                    onChange={(e) => handleInputChange('standard', 'elevation3D', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.elevation3D}
                    onChange={(e) => handleInputChange('premium', 'elevation3D', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td>2D Front Elevation</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.elevation2D}
                    onChange={(e) => handleInputChange('basic', 'elevation2D', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.elevation2D}
                    onChange={(e) => handleInputChange('standard', 'elevation2D', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.elevation2D}
                    onChange={(e) => handleInputChange('premium', 'elevation2D', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td>Working Drawing</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.workingDrawing}
                    onChange={(e) => handleInputChange('basic', 'workingDrawing', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.workingDrawing}
                    onChange={(e) => handleInputChange('standard', 'workingDrawing', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.workingDrawing}
                    onChange={(e) => handleInputChange('premium', 'workingDrawing', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td>MEP Drawing</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.mepDrawing}
                    onChange={(e) => handleInputChange('basic', 'mepDrawing', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.mepDrawing}
                    onChange={(e) => handleInputChange('standard', 'mepDrawing', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.mepDrawing}
                    onChange={(e) => handleInputChange('premium', 'mepDrawing', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td>BOQ Estimate</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.boqEstimate}
                    onChange={(e) => handleInputChange('basic', 'boqEstimate', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.boqEstimate}
                    onChange={(e) => handleInputChange('standard', 'boqEstimate', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.boqEstimate}
                    onChange={(e) => handleInputChange('premium', 'boqEstimate', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td>Design Option</td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.basic.designOption}
                    onChange={(e) => handleInputChange('basic', 'designOption', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.standard.designOption}
                    onChange={(e) => handleInputChange('standard', 'designOption', e.target.checked)}
                  />
                </td>
                <td className="text-center">
                  <input
                    className="checkbox"
                    type="checkbox"
                    checked={pricingFormData.premium.designOption}
                    onChange={(e) => handleInputChange('premium', 'designOption', e.target.checked)}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={4}>
                  <span className="extra-services">
                    + Add extra Services
                  </span>
                </td>
              </tr>
              <tr>
                <td>Pricing</td>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      className="price-input"
                      type="text"
                      placeholder="Type Price"
                      value={pricingFormData.basic.price}
                      onChange={(e) => handleInputChange('basic', 'price', e.target.value)}
                    />
                    <img src={Dollor} alt="" width="9" height="13" />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      className="price-input"
                      type="text"
                      placeholder="Type Price"
                      value={pricingFormData.standard.price}
                      onChange={(e) => handleInputChange('standard', 'price', e.target.value)}
                    />
                    <img src={Dollor} alt="" width="9" height="13" />
                  </div>
                </td>
                <td>
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      className="price-input"
                      type="text"
                      placeholder="Type Price"
                      value={pricingFormData.premium.price}
                      onChange={(e) => handleInputChange('premium', 'price', e.target.value)}
                    />
                    <img src={Dollor} alt="" width="9" height="13" />
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

        <div className="publish-btn-box w-100">
          <Button type="submit" className="themeBtn w-100" size="lg">
            {loading ? (
              <ButtonLoader LoaderWidth='50px' LoaderHeight='50px' />
            ) : (
              "Publish"
            )}
          </Button>

        </div>
      </Form>
    </div>
  );
};
