import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./add_service.styles.scss";
import IconCompleteness from "../../../assets/images/admin/completeness.png";
import IconServicePreviewImage from "../../../assets/images/admin/service-preview-image.png";
import IconBlueI from "../../../assets/images/admin/blue-i.png";
import ProgressBar from "react-bootstrap/ProgressBar";

export const  SideReview = ({uploadForm}) => {
  console.log(uploadForm,'herereere');
    const now = 60;

  return (
    <Col sm={4}>
    <div className="p-4 card-view-section ">
      {" "}
      {/* Listing Completeness */}
      <Row>
        <Col className="completeness-text">Listing Completeness</Col>
        <Col className="completeness-progress-block">
          <ProgressBar variant="success" now={now} label={`${now}%`} />
        </Col>
      </Row>
      {/* Second Section */}
      <Row className="mt-3 gx-3  gy-3">
        <Col sm={4}>
          <img src={IconCompleteness} alt="IconCompleteness" />{" "}
        </Col>
        <Col sm={8} className="complete-listing-info-text">
          100% complete listing gets you more leads
        </Col>
      </Row>
    </div>
    <div className="p-4 card-view-section  mt-3">
      {" "}
      <Row>
        <Col className="completeness-text">
          Your Property Card Preview
        </Col>
      </Row>
      <Row className="mt-3 gx-3 gy-3">
      {uploadForm?.photos?.service_images?.length > 0 ? (
  uploadForm.photos.service_images.map((image, index) => (
    <Col className="" md="6" key={index}>
      <div className="">
        <img
          src={image.preview}
          className='previewImageStyles'
          alt={image.name}
        />
      </div>
    </Col>
  ))
) : (
  <Col className="" md="6">
    <div className="">
      <img
        src={IconServicePreviewImage}
        alt="IconServicePreviewImage"
      />
    </div>
  </Col>
)}


    </Row>
      <Row className="mt-3 gx-3  gy-3">
      <Col>
  <div className="card-view-text">
    {uploadForm?.basic?.bhk_type ? (
      <>
        {uploadForm?.basic?.bhk_type} BHK Apartment
      </>
    ) : 'NULL BHK Apartment'}
    <div className="card-view-subtext">Semi Furnished</div>
  </div>
</Col>

        <Col>
          {/* <div className="card-view-text text-end">10,000</div> */}
        </Col>
      </Row>
      {/* <div className="card-view-body-text">Your Property Address</div> */}
      {/* <div className="card-view-text mt-4">Overview</div> */}
      <Row className="gx-3  gy-3">
   
        <Col>
        <div className="card-view-col-title">Floor</div>
        <div className="card-view-col-value">
  {uploadForm?.basic?.floor_type || 'NULL'}
</div>
      </Col>
  <Col>
    <div className="card-view-col-title">Bathrooms</div>
    <div className="card-view-col-value">{uploadForm?.basic?.bathroom_type || 'NULL'}</div>
  </Col>

      </Row>
      <Row className="mt-2 gx-3  gy-3">
        <Col>
          <div className="card-view-col-title">
            Age of Property (in years)
          </div>
          <div className="card-view-col-value">
            5 <img src={IconBlueI} alt="IconBlueI" />{" "}
          </div>
        </Col>
  <Col>
    <div className="card-view-col-title">Built Up Area</div>
    <div className="card-view-col-value">{uploadForm?.basic?.covered_area || 'NULL'}</div>
  </Col>

      </Row>
      {uploadForm?.amenties?.amenity_type?.length > 0 && (
  <div>
    <div className="card-view-text mt-4">Other Amenities</div>
    <Row className="gx-3 gy-3">
      {uploadForm?.amenties?.amenity_type?.map((amenity) => (
        <Col md="auto" key={amenity.id}>
          <div className="card-view-col-title">{amenity?.icon}{amenity?.name}</div>
        </Col>
      ))}
    </Row>
  </div>
)}

      {/* <div className="card-view-text mt-4">Society Amenities</div>
      <Row className="gx-3  gy-3 mb-5">
        <Col md="auto">
          <div className="card-view-col-title">텁 CCTV</div>
        </Col>
        <Col md="auto">
          <div className="card-view-col-title">턔 Gym</div>
        </Col>
        <Col md="auto">
          <div className="card-view-col-title">텛 Garden</div>
        </Col>
        <Col md="auto">
          <div className="card-view-col-title">위 Gated Community</div>
        </Col>
      </Row> */}
    </div>
  </Col>
  )
}
