import { Col, Row } from 'react-bootstrap'
import styles from './order.module.scss'
import { Card } from '@mui/material'
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const OrderManagment = () => {


    return (
        <div className={styles['order_main']}>
            <Row>
                <Col md={4}>
                    <Card sx={{ background: "#ECF6FF", padding: "30px" }}>
                        <label>New Orders</label>
                        <div className='d-flex alogn-items-center gap-3 mt-4'>
                            <span>245</span>
                            <h6>|</h6>
                            <p className='m-0'>Impression - 20%</p>
                        </div>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card sx={{ background: "#F6F4FF", padding: "30px" }}>
                        <label>Pending Orders</label>
                        <div className='d-flex alogn-items-center gap-3 mt-4'>
                            <span style={{ color: "#5943BF" }}>150</span>
                            <h6>|</h6>
                            <p className='m-0'>Impression - 20%</p>
                        </div>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card sx={{ background: "#FFECE6", padding: "30px" }}>
                        <label>Delivered Orders</label>
                        <div className='d-flex alogn-items-center gap-3 mt-4'>
                            <span style={{ color: "#FA6A2D" }}>220</span>
                            <h6>|</h6>
                            <p className='m-0'>Impression - 20%</p>
                        </div>
                    </Card>
                </Col>
            </Row>
            <div className='mt-2'>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Dessert (100g serving)</TableCell>
                                <TableCell align="right">Calories</TableCell>
                                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                <TableCell align="right">Protein&nbsp;(g)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableCell component="th" scope="row">
                                1
                            </TableCell>
                            <TableCell align="right">2</TableCell>
                            <TableCell align="right">3</TableCell>
                            <TableCell align="right">4</TableCell>
                            <TableCell align="right">5</TableCell>
                            
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )
}
