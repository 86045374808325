import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import styles from './packages.module.scss';
import { SideFilter } from './SideFilter';
import PackageManagment from '../../../services/PackageManagment';
import { ShowErrorMessage, ShowSuccessMessage } from '../../Notification';
import ServiceManagment from '../../../services/ServiceManagment';
import { ButtonLoader } from '../../loader/ButtonLoader';

export const AddPackage = ({ editedPackageData, serviceId }) => {
    const [extraServices, setExtraServices] = useState(6); // Initial number of extra rows
    const [extraServiceInputNames, setExtraServiceInputNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        packageTypesBasic: 'Basic',
        packageTypesStandard: 'Standard',
        packageTypesPremium: 'Premium',
        packageFor: serviceId,
        createdBy: 10,
    });



    useEffect(() => {
        let projectDetails = {};
        let mappedValues = {};
        editedPackageData?.map((item) => {
            if (item.package_types === 'Basic') {
                projectDetails.BasicId = item.id;
                projectDetails.packageBasicName = item.package_name;
                projectDetails.detailsOfferingBasicPackages = item.details_offering_packages;
                projectDetails.packagePricingBasic = item.package_pricing;
                projectDetails.packageDiscountedPricingBasic = item.package_discounted_pricing;
                let packageDetailsPoints = JSON.parse(item.packages_details_points);
                packageDetailsPoints?.forEach((point, index) => {
                    for (const key in point) {
                        const inputName = `${key}`;
                        mappedValues[inputName] = point[key];
                    }
                });
            } else if (item.package_types === 'Standard') {
                projectDetails.StandardId = item.id;
                projectDetails.packageStandardName = item.package_name;
                projectDetails.detailsOfferingStandardPackages = item.details_offering_packages;
                projectDetails.packagePricingStandard = item.package_pricing;
                projectDetails.packageDiscountedPricingStandard = item.package_discounted_pricing;
                let packageDetailsPoints = JSON.parse(item.packages_details_points);
                packageDetailsPoints?.forEach((point, index) => {
                    for (const key in point) {
                        const inputName = `${key}`;
                        mappedValues[inputName] = point[key];
                    }
                });
            } else if (item.package_types === 'Premium') {
                projectDetails.PremiumId = item.id;
                projectDetails.packagePremiumName = item.package_name;
                projectDetails.detailsOfferingPremiumPackages = item.details_offering_packages;
                projectDetails.packagePricingPremium = item.package_pricing;
                projectDetails.packageDiscountedPricingPremium = item.package_discounted_pricing;
                let packageDetailsPoints = JSON.parse(item.packages_details_points);
                packageDetailsPoints?.forEach((point, index) => {
                    for (const key in point) {
                        const inputName = `${key}`;
                        mappedValues[inputName] = point[key];
                    }
                });
            }
        });
        setFormData({ ...formData, ...projectDetails, ...mappedValues });
    }, [editedPackageData]);

    useEffect(() => {
        console.log(formData, 'formmmmmmmmmmmmmmm');
    }, [formData]);

    const renderExtraServicesRow = (index) => {
        const serviceInputs = ['Basic', 'Standard', 'Premium'];
        return (
            <TableRow key={index}>
                <TableCell>
                    <button className={styles.editButton}></button>
                    <button className={styles.saveButton}></button>
                </TableCell>
                {serviceInputs.map((serviceType) => (
                    <TableCell align="center" key={serviceType}>
                        <input
                            type="text"
                            name={`${serviceType}_input${index}`}
                            placeholder={`Enter data for ${serviceType}`}
                            onChange={(e) => handleInputChange(e)}
                            value={formData[`${serviceType}_input${index}`]}
                        />
                    </TableCell>
                ))}
            </TableRow>
        );
    };

    const addExtraServiceRow = () => {
        setExtraServices(extraServices + 1); // Add one extra row
    };



    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            packageFor: serviceId,
        }));
    }, [serviceId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePublishClick = async () => {
        setLoading(true);
        handlePackageSubmit('Active');
    };

    const handleSaveClick = async () => {
        setLoading(true);
        handlePackageSubmit('New');
    };

    const handlePackageSubmit = async (packageStatus) => {
        const packageTypes = ['Basic', 'Standard', 'Premium'];
        const postData = {
            ...formData,
            packageTypes,
            packageStatus, // Include the status in the postData
        };
        packageTypes.forEach((packageType) => {
            const packageDetailsPoints = [];
            for (let index = 0; index < extraServices; index++) {
                packageDetailsPoints.push({
                    [`${packageType}_input${index}`]: formData[`${packageType}_input${index}`] || '',
                });
            }
            postData[`packageDetailsPoints${packageType}`] = packageDetailsPoints;
        });
        try {
            if (!editedPackageData) {
                const response = await PackageManagment.createPackage(postData);
                if (response.status === 200) {
                    ShowSuccessMessage(response.message);
                    window.location.reload(false)
                } else {
                    ShowErrorMessage(response.message);
                }
            } else {
                const response = await PackageManagment.updatePackage(postData);
                if (response.status === 200) {
                    ShowSuccessMessage(response.message);
                    window.location.reload(false)

                } else {
                    ShowErrorMessage(response.message);
                }
            }
        } catch (error) {
            ShowErrorMessage(error.response.data.message);
        }
    };

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    return (
        <div className="col-8">
            <div className={`${styles.packages_table} mt-5 col-9`}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350, border: '1px solid #DADBDD' }} aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell rowSpan={3}>Details in packages (Editable)</TableCell>
                                <TableCell align="center" sx={{ background: '#FAFAFA', color: '#62646a' }}>
                                    Basic<input type="hidden" name="packageTypesBasic" value="Basic" />
                                </TableCell>
                                <TableCell align="center" sx={{ background: '#FAFAFA', color: '#62646a' }}>
                                    Standard <input type="hidden" name="packageTypesStandard" value="Standard" />
                                </TableCell>
                                <TableCell align="center" sx={{ background: '#FAFAFA', color: '#62646a' }}>
                                    Premium <input type="hidden" name="packageTypesPremium" value="Premium" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" sx={{ background: 'red' }}>
                                    <input type="text" name="packageBasicName" value={formData.packageBasicName} onChange={(e) => handleInputChange(e)} placeholder="Name your package" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packageStandardName" value={formData.packageStandardName} onChange={(e) => handleInputChange(e)} placeholder="Name your package" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packagePremiumName" value={formData.packagePremiumName} onChange={(e) => handleInputChange(e)} placeholder="Name your package" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">
                                    <input type="text" name="detailsOfferingBasicPackages" onChange={(e) => handleInputChange(e)} value={formData.detailsOfferingBasicPackages} placeholder="Describe the details of your offerings" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="detailsOfferingStandardPackages" onChange={(e) => handleInputChange(e)} value={formData.detailsOfferingStandardPackages} placeholder="Describe the details of your offerings" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="detailsOfferingPremiumPackages" onChange={(e) => handleInputChange(e)} value={formData.detailsOfferingPremiumPackages} placeholder="Describe the details of your offerings" />
                                </TableCell>
                            </TableRow>

                            {Array.from({ length: extraServices }, (_, index) => renderExtraServicesRow(index))}
                            <span className={styles.addbtn2} onClick={addExtraServiceRow}>
                                + Add extra services
                            </span>
                            <TableRow>
                                <TableCell>Pricing</TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packagePricingBasic" onChange={(e) => handleInputChange(e)} value={formData.packagePricingBasic} placeholder="Type Price $" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packagePricingStandard" onChange={(e) => handleInputChange(e)} value={formData.packagePricingStandard} placeholder="Type Price $" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packagePricingPremium" onChange={(e) => handleInputChange(e)} value={formData.packagePricingPremium} placeholder="Type Price $" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Discounted Pricing</TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packageDiscountedPricingBasic" onChange={(e) => handleInputChange(e)} value={formData.packageDiscountedPricingBasic} placeholder="Type Price $" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packageDiscountedPricingStandard" onChange={(e) => handleInputChange(e)} value={formData.packageDiscountedPricingStandard} placeholder="Type Price $" />
                                </TableCell>
                                <TableCell align="center">
                                    <input type="text" name="packageDiscountedPricingPremium" onChange={(e) => handleInputChange(e)} value={formData.packageDiscountedPricingPremium} placeholder="Type Price $" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={`${styles.bottom_box} w-100 gap-3 d-flex mb-3`}>
                    <div className='w-50'>
                        <button className={styles.publishbtn} onClick={handlePublishClick}>
                            {loading ? <ButtonLoader LoaderWidth="50px" LoaderHeight="50px" /> : 'Publish'}
                        </button>
                    </div>
                    <div className='w-50'>
                        <button className={styles.savebtn} onClick={handleSaveClick} >
                            {loading ? <ButtonLoader LoaderWidth="50px" LoaderHeight="50px" /> : 'Save'}
                        </button>
                    </div>


                </div>
            </div>
        </div>
    );
};
