import { Card, Col, Row, Table } from 'react-bootstrap'
import styles from './dashboard.module.scss'
import card_user from '../../../assets/images/card-advance-sale.png'
import { AiOutlinePieChart } from 'react-icons/ai';
import { AiOutlineUsergroupDelete } from 'react-icons/ai';
import UsersManagement from '../users-management/users';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Dowload from '../../../assets/images/svg/dowload.svg'
import Dot from '../../../assets/images/svg/threedot.svg'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import ServiceType from './charts/serviceType';
import SalesType from './charts/salesType';
import Orders from './charts/Orders';
import ProjectProcess from './charts/projectProcess';
import Dotbg from '../../../assets/images/svg/dotbg.svg';
import circlebg from '../../../assets/images/svg/circle.svg'


export const Dashboard = () => {
  const now = 60;
  return (
    <div className={styles.dashboard_main}>
      <div className={`${styles.ListingMain} row`}>
        <div className='col-8'>
          <h1>Hi Vaibhav Choudhary</h1>
          <p>Welcome back to Planningg.homes Dashboard</p>
        </div>
        <div className='col-4'>
          <button className={styles.downloadbtn}>
            Download
            <img src={Dowload} alt='svg' className='ms-3' />
          </button>
          <Link to=''> <button className={styles.addbtn}> + Add User</button></Link>
        </div>
      </div>
      <Row>
        <Col>
          <div className={styles.dashboard_box}>
            <span>$22,880.50</span>
            <label>Won from 18 Deals</label>
            <ProgressBar now={now} label={`${now}%`} />
          </div>
        </Col>
        <Col>
          <div className={styles.dashboard_box}>
            <span>$22,880.50</span>
            <label>Won from 18 Deals</label>
            <ProgressBar className={styles.progress_bar} now={now} label={`${now}%`} />
          </div>
        </Col>
        <Col>
          <div className={styles.dashboard_box}>
            <span>$22,880.50</span>
            <label>Won from 18 Deals</label>
            <ProgressBar now={now} label={`${now}%`} />
          </div>
        </Col>
        <Col>
          <div className={styles.dashboard_box}>
            <span>$22,880.50</span>
            <label>Won from 18 Deals</label>
            <ProgressBar now={now} label={`${now}%`} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col className={`${styles.left_section} col-xl-8  d-flex col-lg-8 col-md-6`}>
          <div className={`${styles.inner_box} col-md-6 me-4`}>
            <span>Services</span>
            <ServiceType />

          </div>
          <div className={`${styles.inner_box} col-xl-6  col-lg-6 col-md-6`}>

            <span>Sales</span>
            <SalesType />
          </div>
        </Col>
        <Col className={`${styles.right_section} col-xl-4 col-lg-4 col-md-6`}>
          <div className={`${styles.inner_box} col-xl-12 ms-4 me-2 col-lg-12`}>

            <span>Orders</span>
            <Orders />
          </div>
        </Col>
      </Row>

      <Row>
        <Col className={`${styles.left_section} col-xl-8 d-flex mt-5 col-lg-8 col-md-6`}>
          <div className={`${styles.inner_box} col-md-12`}>
            <div className='d-flex  flex-column'>
              <span>Top Selling Service</span>
              <label className='mt-2'>Last 2 Weeks</label>
            </div>

            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Ref</th>
                  <th>Leads</th>
                  <th>Deals</th>
                  <th>Payment Recieved</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>187</td>
                  <td>235</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>140</td>
                  <td>115</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>129</td>
                  <td>239</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>150</td>
                  <td>247</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
              </tbody>
            </Table>
          </div>

        </Col>
        <Col className={`${styles.right_section} col-xl-4 mt-5 col-lg-4 col-md-6`}>
          <div className={`${styles.inner_box} col-xl-12 d-flex flex-column ms-4 me-2 col-lg-12`}>
            <div className='d-flex w-100'>
              <div className='w-75 d-flex flex-column'>
                <span>Tasks</span>
                <label className='ps-1'>4 of 8 remaining</label>
              </div>
              <div className='w-25 text-end'>
                <img src={Dot} alt='svg' className='ms-3' />
              </div>
            </div>
            <Form>
              <div className='d-flex ms-1 mt-5 mb-3'>
                <input type='checkbox'></input>
                <small>Marketing dashboard app</small>
              </div>
              <div className='d-flex ms-2 mb-3'>
                <input type='checkbox'></input>
                <small>Create new version 4</small>
              </div>
              <div className='d-flex ms-2 mb-3'>
                <input type='checkbox'></input>
                <small>User Testing</small>
              </div>
              <div className='d-flex ms-2 mb-3'>
                <input type='checkbox'></input>
                <small>Design system</small>
              </div>
              <div className='d-flex ms-2 mb-3'>
                <input type='checkbox'></input>
                <small>Awesome task</small>
              </div>
              <div className='d-flex ms-2 mb-5'>
                <input type='checkbox'></input>
                <small>Marketing dahsboard concept</small>
              </div>
              <div className=''>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Add new todo"

                  />
                  <Button className={`${styles.add_button}`} variant="outline-secondary" id="button-addon2">
                    Add
                  </Button>
                </InputGroup>
              </div>
            </Form>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className={`${styles.left_section} col-xl-8 d-flex mt-5 col-lg-8 col-md-6`}>
          <div className={`${styles.inner_box} col-md-12`}>

            <div className='d-flex mb-3 flex-column'>
              <span>Top Selling Service</span>
              <label className='mt-2'>Last 2 Weeks</label>
            </div>
            <Table className={`${styles.table_box}`} striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Ref</th>
                  <th>Leads</th>
                  <th>Deals</th>
                  <th>Payment Recieved</th>
                  <th>Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>187</td>
                  <td>235</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>150</td>
                  <td>230</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>120</td>
                  <td>230</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>
                    <div className='d-flex flex-column'>
                      <small>Interior Design</small>
                      <label>Rs 8,192.000</label>
                    </div>
                  </td>
                  <td>170</td>
                  <td>210</td>
                  <td>Rs 8,192.00</td>
                  <td>100%</td>
                </tr>
              </tbody>
            </Table>
          </div>

        </Col>
        <Col className={`${styles.right_section} col-xl-4 mt-5 col-lg-4 col-md-6`}>
          <div className={`${styles.inner_box} col-xl-12 ms-4 me-2 col-lg-12`}>
            <span>Project Process</span>
            <ProjectProcess />
          </div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col className={`${styles.left_section} col-xl-8  d-flex col-lg-8 col-md-6`}>
          <div className={`${styles.inner_box} col-md-6 me-3`}>
            <div className='d-flex  flex-column'>
              <span>Recently Added Design</span>
              <label className='mt-2'>Last 2 Weeks</label>
            </div>
            <div className={`${styles.designing_representation} mt-4`}>
              <div className=''>

              </div>
              <div className='w-100 d-flex'>

                <div className={`${styles.designing_box} w-25`}>

                </div>
                <div className={`${styles.designing_representation} w-75 `}>
                  <p className='ms-3 mb-1'>Design of 15ft X 45ft House De....</p>
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Interior Design</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Building Type</label>
                    </div>
                  </div>
                  <div className='d-flex w-100 mb-4'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Design Type</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Plot Area</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex mt-3'>

                <div className={`${styles.designing_box} w-25`}>

                </div>
                <div className={`${styles.designing_representation} w-75 ps-1`}>
                  <p className='ms-3 mb-1'>Design of 15ft X 45ft House De....</p>
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Interior Design</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Building Type</label>
                    </div>
                  </div>
                  <div className='d-flex w-100 mb-4'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Design Type</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Plot Area</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex mt-3'>

                <div className={`${styles.designing_box} w-25`}>

                </div>
                <div className={`${styles.designing_representation} w-75 ps-1`}>
                  <p className='ms-4 mb-1'>Design of 15ft X 45ft House De....</p>
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label className=''>Interior Design</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Building Type</label>
                    </div>
                  </div>
                  <div className='d-flex w-100 mb-4'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Design Type</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-3 me-3' />
                      <label>Plot Area</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-100 d-flex mt-3'>

                <div className={`${styles.designing_box} w-25`}>

                </div>
                <div className={`${styles.designing_representation} w-75 ps-1`}>
                  <p className='ms-4 mb-1'>Design of 15ft X 45ft House De....</p>
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-4 me-3' />
                      <label>Interior Design</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-4 me-3' />
                      <label>Building Type</label>
                    </div>
                  </div>
                  <div className='d-flex w-100'>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-4 me-3' />
                      <label>Design Type</label>
                    </div>
                    <div className='w-50'>
                      <img src={Dotbg} alt='svg' className='ms-4 me-3' />
                      <label>Plot Area</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className={`${styles.inner_box} col-xl-6  col-lg-6 col-md-6`}>

            <span className='mb-4'>Recently Activity</span>

            <div className={`${styles.designing_representation}`}>
              <div className=''>

              </div>
              <div className='w-100 d-flex mt-5'>

                <div className=''>
                  <img src={circlebg} alt='svg' className=' me-2' />
                </div>
                <div className={`${styles.designing_representation} mb-3 w-75 ps-1`}>
                  <p className='ms-1 '>Purchased by Hemant Dhiman</p>
                  <div className='d-flex w-100'>
                    <div className='d-flex flex-column'>
                      <label className='ms-1'>ID 54124  |  Product Interior Design</label>
                      <label className='ms-1'>12 : 40 PM Today</label>
                    </div>

                  </div>

                </div>
              </div>
              <div className='w-100 d-flex mt-3'>

                <div className=''>
                  <img src={circlebg} alt='svg' className=' me-2' />
                </div>
                <div className={`${styles.designing_representation}  mb-3 w-75 ps-1`}>
                  <p className='ms-1'>Shivam Dhiman have liked the product</p>
                  <div className='d-flex w-100'>
                    <div className='d-flex flex-column'>
                      <label className='ms-1'>ID 54124  |  Product Interior Design</label>
                      <label className='ms-1'>12 : 40 PM Today</label>
                    </div>

                  </div>

                </div>
              </div>
              <div className='w-100 d-flex mt-3'>
                <div className=''>
                  <img src={circlebg} alt='svg' className='' />
                </div>
                <div className={`${styles.designing_representation}  mb-3 w-75`}>
                  <p className='ms-3'>Purchase by Sanyam Dixit</p>
                  <div className='d-flex w-100'>
                    <div className='d-flex flex-column'>
                      <label className='ms-3'>ID 54124  |  Product Interior Design</label>
                      <label className='ms-3'>12 : 40 PM Today</label>
                    </div>
                  </div>

                </div>
              </div>
              <div className='w-100 d-flex mt-3'>

                <div className=''>
                  <img src={circlebg} alt='svg' className={`${styles.timline_img} me-2`} />
                </div>
                <div className={`${styles.designing_representation}  mb-3 w-75`}>
                  <p className='ms-3'>Shivam Dhiman have liked the product</p>
                  <div className='d-flex w-100'>
                    <div className='d-flex flex-column'>
                      <label className='ms-3'>ID 54124  |  Product Interior Design</label>
                      <label className='ms-3'>12 : 40 PM Today</label>
                    </div>

                  </div>

                </div>
              </div>
            </div>

          </div>
        </Col>
        <Col className={`${styles.right_section} col-xl-4 col-lg-4 col-md-6`}>
          <div className={`${styles.inner_box} ps-0 pe-0 col-xl-12 ms-4 me-2 col-lg-12`}>
            <div className={`${styles.customer_feedbackbox} d-flex flex-column`}>
              <span>Customer Feedback</span>
              <label className='mt-2'>Last 2 Weeks</label>
              <div className={`${styles.customer_mainbox} w-100 d-flex gap-3`}>
                <div className={`${styles.customer_box}`}>
                </div>
                <div className={`${styles.customer_content}`}>
                  <div className='w-100 d-flex'>
                    <div className='w-50'> <p className='mb-0'>Yash Choudhary</p></div>
                    <div className='w-50 d-flex justify-content-end'><label className='text-end'>25 Dec 2023</label></div>
                  </div>
                  <label>star- 4</label>
                  <label>There is as lot packed in this template.The Savings in time and energy is so worth it.</label>
                </div>
              </div>
              <div className={`${styles.customer_mainbox} w-100 d-flex gap-3`}>
                <div className={`${styles.customer_box}`}>
                </div>
                <div className={`${styles.customer_content}`}>
                  <div className='w-100 d-flex'>
                    <div className='w-50'> <p className='mb-0'>Yash Choudhary</p></div>
                    <div className='w-50 d-flex justify-content-end'><label className='text-end'>25 Dec 2023</label></div>
                  </div>
                  <label>star- 4</label>
                  <label>There is as lot packed in this template.The Savings in time and energy is so worth it.</label>
                </div>
              </div>
              <div className={`${styles.customer_mainbox} w-100 d-flex gap-3`}>
                <div className={`${styles.customer_box}`}>
                </div>
                <div className={`${styles.customer_content}`}>
                  <div className='w-100 d-flex'>
                    <div className='w-50'> <p className='mb-0'>Yash Choudhary</p></div>
                    <div className='w-50 d-flex justify-content-end'><label className='text-end'>25 Dec 2023</label></div>
                  </div>
                  <label>star- 4</label>
                  <label>There is as lot packed in this template.The Savings in time and energy is so worth it.</label>
                </div>
              </div>

            </div>
          </div>
        </Col>
      </Row>


      <Row className='mt-5'>
        <Col className='col-xl-4  col-lg-5 col-12'>
          <Card>
            <div className="d-flex align-items-center row">
              <div className='col-7'>
                <div className='card-body text-nowrap'>
                  <h5 className="card-title mb-0">Congratulations John! 🎉</h5>
                  <p className="mb-2">Best seller of the month</p>
                </div>
              </div>
              <div className='col-5 text-center text-sm-left'>
                <div className='card-body pb-0 px-0 px-md-4'>
                  <img src={card_user} alt='' height={'140'} />
                </div>
              </div>
            </div>
          </Card>
        </Col>
        <div className="col-xl-8  col-lg-7 col-12">
          <div className="card h-100">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-4">
                <h5 className="card-title mb-0">Statistics</h5>
                <small className="text-muted">Updated 1 month ago</small>
              </div>
              <div className="row gy-3">
                <div className="col-md-3 col-6">
                  <div className="d-flex align-items-center gap-2">
                    <span className={styles.pieChart}><AiOutlinePieChart style={{ border: "2px solid #eae8fd  " }} /></span>
                    <div className="card-info">
                      <h5 className="mb-0">230k</h5>
                      <small>Sales</small>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="d-flex align-items-center gap-2">
                    <span className={styles.userIcon}><AiOutlineUsergroupDelete style={{ border: "2px solid #eae8fd  " }} /></span>
                    <div className="card-info">
                      <h5 className="mb-0">8.549k</h5>
                      <small>Customers</small>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="d-flex align-items-center gap-2">
                    <span className={styles.pieChart}><AiOutlinePieChart style={{ border: "2px solid #eae8fd  " }} /></span>
                    <div className="card-info">
                      <h5 className="mb-0">1.423k</h5>
                      <small>Products</small>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-6">
                  <div className="d-flex align-items-center gap-2">
                    <span className={styles.pieChart}><AiOutlinePieChart style={{ border: "2px solid #eae8fd  " }} /></span>
                    <div className="card-info">
                      <h5 className="mb-0">$9745</h5>
                      <small>Revenue</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row >
      <div className='mt-3'>
      </div>
    </div >
  )
}
