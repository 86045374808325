import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css'; // Import calendar styles
import moment from 'moment';
import styles from './calendar.module.scss';
const localizer = momentLocalizer(moment);

const events = [
    {
        title: 'Payment Done Event',
        start: new Date(2023, 8, 1, 10, 0), // Month is 0-based (8 represents September)
        end: new Date(2023, 8, 1, 12, 0),
        paymentStatus: 'done', // You can use a property to track payment status
    },
    {
        title: 'Payment Not Done Event',
        start: new Date(2023, 8, 15, 14, 0),
        end: new Date(2023, 8, 15, 16, 0),
        paymentStatus: 'notDone',
    },
];

export const CalendarView = () => {
    const eventStyleGetter = (event, start, end, isSelected) => {
        let eventStyle = {};

        if (event.paymentStatus === 'done') {
            eventStyle.backgroundColor = 'green'; // Green for payment done
        } else if (event.paymentStatus === 'notDone') {
            eventStyle.backgroundColor = '#3174AD'; // Blue (#3174AD) for payment not done
        }

        return {
            style: eventStyle,
        };
    };

    // Define a function to render custom elements (dots) on events
    const eventContent = (event) => (
        <div>
            <div>{event.title}</div>
            {event.paymentStatus === 'done' && (
                <span style={{ backgroundColor: 'green', width: '10px', height: '10px', display: 'inline-block', marginLeft: '5px' }}></span>
            )}
            {event.paymentStatus === 'notDone' && (
                <span style={{ backgroundColor: '#3174AD', width: '10px', height: '10px', display: 'inline-block', marginLeft: '5px' }}></span>
            )}
        </div>
    );
    return (
        <div className={styles.mainContainerCalendar}>

            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                eventPropGetter={eventStyleGetter}
                components={{
                    event: eventContent,
                }}
            />
            <div className={styles.colorDisplayBox}>
                <div className={styles.paymentDone}>
                    <div className={styles.paymentDoneColorBox}>

                    </div>
                    <div className={styles.paymentDoneColorBoxText}>
                        <h3 className={styles.fontText}>payment✔️</h3>
                    </div>
                </div>
                <div className={styles.paymentNotDone}>
                    <div className={styles.paymentNotDoneColorBox}>
                    
                    </div>
                    <div className={styles.paymentNotDoneColorBoxText}>
                    <h3 className={styles.fontText}>payment❌</h3>
                    </div>
                </div>
            </div>
        </div>
    );
}
