import { BrowserRouter } from "react-router-dom";
import { Navigation } from "./components/routes";

function App() {
  return (
    <>
      <BrowserRouter>
          <Navigation />
      </BrowserRouter>
    </>
  );
}

export default App;
