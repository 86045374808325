import fetch from "./FetchInterceptor";
const PackageManagment = {};

PackageManagment.createPackage = function (data) {
  return fetch({
    url: "admin/create_packages",
    method: "post",
    data: data,
  });
};
PackageManagment.updatePackage = function (data) {
  return fetch({
    url: "admin/update_packages",
    method: "post",
    data: data,
  });
};
PackageManagment.getPackageDetails = function (id, packageStatus) {
  return fetch({
      url: `admin/get_packages/${id}/${packageStatus}`,
      method: "get",
      data: {},
  });
};

  PackageManagment.getCreatedPackage = function () {
    return fetch({
      url: "admin/get-created-package",
      method: "get",
      data: {},
    });
  };

  PackageManagment.deletePackage = function (data) {
    return fetch ({
      url: "admin/delete-package",
      method:'post',
      data:data,
    });
  }
  
  
export default PackageManagment;
