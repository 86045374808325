import React, { useEffect, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

function SunEditorComponent({contents,setcontents,form}) {
  const [editorContent, setEditorContent] = useState("");

  useEffect(() => {
    setEditorContent(String(form?.description?.service_description));
  }, [form]);


  const onChangeEvent = (content) => {
    console.log(content);
    setcontents(content);
  };
  const onScrollEvent = (event) => {
    console.log(event);
  };
  const onClickEvent = (event) => {
    console.log(event);
  };
  const onMouseDownEvent = (event) => {
    console.log(event);
  };
  const onKeyUpEvent = (event) => {
    console.log(event);
  };
  const onFocusEvent = (event) => {
    console.log(event);
  };
  return (
    <>
      <SunEditor
        name="myeditor"
        placeholder="Type somthing here..."
        defaultValue={editorContent}
        onChange={onChangeEvent}
        onScroll={onScrollEvent}
        onClick={onClickEvent}
        onMouseDown={onMouseDownEvent}
        onKeyUp={onKeyUpEvent}
        onFocus={onFocusEvent}
        setOptions={{
          buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle", "blockquote"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["fontColor", "hiliteColor", "textStyle"],
            ["removeFormat"],
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image", "video", "audio"],
            ["imageGallery"],
            ["fullScreen", "showBlocks", "codeView"],
            ["preview", "print"],
            ["save", "template"],
          ],
        }}
      />
    </>
  );
}
export default SunEditorComponent;
