import React, { useEffect, useState } from 'react';
import { getCalendarlyEvents } from './calendarlyEvents';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/Container';
import styles from './leads.module.scss';

function EventList() {
    const [events, setEvents] = useState([]);
    const [activeTab, setActiveTab] = useState('Upcoming'); // Default to 'Upcoming'

    useEffect(() => {
        async function fetchEvents() {
            try {
                const data = await getCalendarlyEvents();
                setEvents(data);
            } catch (error) {
                // Handle error as needed
            }
        }

        fetchEvents();
    }, []);

    return (
        <Container className="px-4">
            <Row className="gx-4  gy-4">
                <div className='col-sm-12'>
                    <div className={`p-4 ${styles.events_listing}`}>
                        <Row>
                            <Col sm={8}>
                                <div className={styles.page_title}>Event List For Service</div>
                            </Col>

                        </Row>
                        <div className={styles.tabs_Title}>
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(selectedTab) => setActiveTab(selectedTab)}
                                id="event-tabs"
                            >
                                <Tab eventKey="Upcoming" title={`Upcoming (${getTabCount('Upcoming')})`}>
                                    {displayEvents('Upcoming')}
                                </Tab>
                                <Tab eventKey="Pending" title={`Pending (${getTabCount('Pending')})`}>
                                    {displayEvents('Pending')}
                                </Tab>
                                <Tab eventKey="Past" title={`Past (${getTabCount('Past')})`}>
                                    {displayEvents('Past')}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </Row>
        </Container>
    );

    function displayEvents(tab) {
        if (!Array.isArray(events.collection)) {
            return <p>No events found for this tab.</p>;
        }

        const filteredEvents = events.collection.filter((event) => {
            const currentDate = new Date();
            const startDate = new Date(event.start_time);
            const endDate = new Date(event.end_time);

            if (tab === 'Upcoming') {
                return startDate > currentDate;
            } else if (tab === 'Pending') {
                return startDate <= currentDate && endDate >= currentDate;
            } else if (tab === 'Past') {
                return endDate < currentDate;
            }

            return false;
        });

        if (Array.isArray(filteredEvents) && filteredEvents.length > 0) {
            return (
                <>
    {filteredEvents.map((event) => (
        <div className={styles.event_box}>
            <ul>
            <a href="https://calendly.com/app/scheduled_events/user/me" className={styles.anchorLink} target="_blank">
                <li key={event.uri}>
                    <strong>Name: {event.name}</strong>
                    <br />
                    Status: {event.status}
                    <br />
                    Start Time: {new Date(event.start_time).toLocaleString()}
                    <br />
                    End Time: {new Date(event.end_time).toLocaleString()}
                    <br />
                    {/* Adding the link */}
                </li>
                </a>
            </ul>
        </div>
    ))}
</>

            );
        } else {
            return <p>No events found for this tab.</p>;
        }
    }

    function getTabCount(tab) {
        if (Array.isArray(events.collection)) {
            return events.collection.filter((event) => {
                const currentDate = new Date();
                const startDate = new Date(event.start_time);
                const endDate = new Date(event.end_time);

                if (tab === 'Upcoming') {
                    return startDate > currentDate;
                } else if (tab === 'Pending') {
                    return startDate <= currentDate && endDate >= currentDate;
                } else if (tab === 'Past') {
                    return endDate < currentDate;
                }

                return false;
            }).length;
        }

        return 0;
    }
}

export default EventList;
