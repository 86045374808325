import { useState } from "react";
//https://www.npmjs.com/package/react-toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Navigate } from "react-router-dom";
import {
  AUTH_TOKEN,
  DEFAULT_ADMIN_PAGE,
} from "../../../config/constants/AuthConstant";

export const PublicLayout = ({ children }) => {
  let Auth = localStorage.getItem(AUTH_TOKEN);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {!Auth ? children : <Navigate to={DEFAULT_ADMIN_PAGE} />}
      {/* {children} */}
    </>
  );
};
