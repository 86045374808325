import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./add_service.styles.scss";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { amenties,preferredFor } from "./dataArray";
export const Amenities = ({form,setForm,nextStepAmenity}) => {

  const handleTypeCheckBox = (amenity, name, type) => {
    let amenityTypes = form[type][name] ? form[type][name] : [];
    if (!amenityTypes.filter((item) => item.value === amenity.value)?.length) {
      amenityTypes.push(amenity);
    }
    else {
      amenityTypes = amenityTypes.filter((item) => item.value !== amenity.value);
    }
    setForm({ ...form, [type]: { ...form[type], [name]: amenityTypes } });
  };
  const handleProjectTypeRadio = (project, name, type) => {
    setForm({ ...form, [type]: { ...form[type], [name]: project.value } });
  };
  const handleDropdownChange = (selectedValue, type, subtype) => {
    setForm({ ...form, [type]: { ...form[type], [subtype]: selectedValue } });
  };

    return (
      <>
        <Form className="ServiceForm" onSubmit={nextStepAmenity}>
          {/* Other Animities form goup*/}
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>
              Other Animities <span className="required">*</span>
            </Form.Label>
            <div className="mt-1">
            <Row className="gx-3 gy-4">
              {amenties?.map((amenity) => (
                <Col md="auto" key={amenity.value}>
                  <div
                    className={`other-animities ${form?.amenties?.amenity_type?.filter((item) => item.value == amenity.value)?.length ? 'active' : ''}`}
                    onClick={() => handleTypeCheckBox(amenity, "amenity_type", "amenties")}
                  >
                    <input
                      type="checkbox"
                      name={amenity.name}
                      value={amenity.value}
                      style={{ display: 'none' }}
                      checked={form?.amenity?.amenity_type?.filter((item) => item.value == amenity.value)?.length}
                    />
                    <div className="animities-content">{amenity.icon}</div>{" "}
                    <div className="animities-text">{amenity.name}</div>
                  </div>
                </Col>
              ))}
              {/* Add similar code for other room types */}
            </Row>
            </div>
          </Form.Group>
          {/* Vaastu Compliance form goup*/}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              Vaastu Compliance <span className="required">*</span>
            </Form.Label>
            <Row>
              <Col>
                <Form.Select onChange={(e) => handleDropdownChange(e.target.value,'amenties','vastu_compliance')} value={form?.amenities?.vastu_compliance} aria-label="Default select example" >
                  <option>Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Select>
              </Col>
              <Col></Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Preferred For</Form.Label>
            {/* <Form.Control type="email" placeholder="name@example.com" /> */}
            <div className="mt-1">
            <Row className="gx-3 gy-4">
              {preferredFor?.map((preferred) => (
                <Col md="auto" key={preferred.value}>
                  <div
                    className={`preferred-for-item ${form?.amenties?.preferred_category === preferred.value ? 'active' : ''}`}
                    onClick={() => handleProjectTypeRadio(preferred, "preferred_category", "amenties")}
                  >
                            <img
                      src={preferred.icon}
                      className="preferred-for-item-img"
                    />
                    <input
                      type="radio"
                      name={preferred.name}
                      value={preferred.value}
                      style={{ display: 'none' }}
                      checked={form?.amenties?.preferred_category === preferred.value}
                    />
                    &nbsp;
                    <span className="preferred-for-item-text">{preferred.name}</span>
                  </div>
                </Col>
              ))}

              {/* Add similar code for other categories */}
            </Row>
            </div>
          </Form.Group>
  
          {/* Submit Button */}
          <div className="d-grid gap-2 mb-3 mt-5 ">
            <Button type="submit" className="themeBtn" size="lg">
              Continue
            </Button>
          </div>
        </Form>
      </>
    );
  };