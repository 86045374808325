import React, { useEffect, useState } from 'react'
import styles from './packages.module.scss'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ServiceManagment from '../../../services/ServiceManagment';

export const TtileDetails = ({ selectedPackageStatus, selectedPackageName, isAddPackageVisible, packageData, editedPackageData, projectTypes, handleChange }) => {


    console.log(isAddPackageVisible, 'isAddPackageVisible');
    console.log(editedPackageData, 'editedPackageData');
    console.log(packageData, 'packageData')
    return (
        <div className='col-8 d-flex'>
            <div className={styles.pricinglabel}>
                {(!isAddPackageVisible && editedPackageData === null && packageData.length > 0) ? (
                    <div className={styles.text_wrapper}>Show pricing for: <span>{selectedPackageName} {selectedPackageStatus}</span></div>
                ) : isAddPackageVisible && !editedPackageData ? (

                    <div className={`${styles.text_wrapper} d-flex`}>
                        Add pricing for:
                        <div className={`${styles.pricing_dropdownbox} ms-3`}>
                            <FormControl sx={{ minWidth: 200 }}>
                                <InputLabel id="demo-simple-select-helper-label">Package Service</InputLabel>
                                <Select
                                    labelId="package-type-select"
                                    id="package-type"
                                    label="Package Type"
                                    onChange={handleChange}
                                >
                                    {projectTypes.map((data) => (
                                        <MenuItem value={data?.id}>{data?.name}</MenuItem>
                                    ))}
                                </Select>


                            </FormControl>
                        </div>
                    </div>
                ) : editedPackageData ? (
                    <div className={styles.text_wrapper}>Edit pricing for: <span>{selectedPackageName} {selectedPackageStatus}</span></div>

                ) : <div className={styles.text_wrapper}>Show pricing for: <span>{selectedPackageName} {selectedPackageStatus}</span></div>
                }
            </div>
        </div>
    )
}
