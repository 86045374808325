import { useState } from "react";
import Logo from "../../assets/images/transparent-logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../index.styles.scss";
import { Link } from "react-router-dom";
import AuthService from "../../services/AuthService";
import {
  AUTH_TOKEN,
  DEFAULT_ADMIN_PAGE,
} from "../../config/constants/AuthConstant";

import {
  ShowErrorMessage,
  ShowSuccessMessage,
} from "../../components/Notification";
import { useNavigate } from "react-router-dom";

export const LoginRegister = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    event.preventDefault();
    event.stopPropagation();
    const response = await AuthService.login({ email, password });
    if (response.data?.status === true) {
      console.log(response);
      const token = response.data.token;
      const first_name = response.data.first_name;
      const role = response.data.role;
      const userId = response.data.userId;
      localStorage.setItem(AUTH_TOKEN, token);
      localStorage.setItem("first_name", first_name);
      localStorage.setItem("role", role);
      localStorage.setItem("userId",userId)
      setValidated(true);
      ShowSuccessMessage(response.message);

      setValidated(true);
      navigate(DEFAULT_ADMIN_PAGE);
    } else {
      ShowErrorMessage(response.message);
    }
    console.log(response);
  };

  return (
    <>
      <div className="login_main_page w-100 d-flex">
        <div className="row w-100">
          <div className="col-md-5 left_login_page">
            <img src={Logo} alt="" height="" width="123px" />
            <div className="login-center-content">
              <label>We're glad to see you again!</label>
              <h2>Welcome to Admin Portal</h2>
            </div>
          </div>
          <div className="col-md-7 col-12 right_login_page d-flex justify-content-center align-items-center">
            <div className="form-section">
              <div className="d-flex gap-3 align-items-center">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="form-floating mb-3" controlId="email">
                    <Form.Control
                      required
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="form-control"
                      id="floatingInput"
                    />
                    <Form.Label htmlFor="floatingInput">Email address</Form.Label>
                    <Form.Control.Feedback type="invalid">
                      Email address is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="form-floating mb-3" controlId="password">
                    <Form.Control
                      required
                      type="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="form-control"
                      id="floatingInput"
                    />
                    <Form.Label htmlFor="floatingInput">Password</Form.Label>
                    <Link to="/forgot-password" className="float-end link mt-3">
                      Forgot Password?
                    </Link>
                    <Form.Control.Feedback type="invalid">
                    Password is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="clearfix"></div>
                  <div className="d-grid gap-2 mb-3 mt-3">
                    <Button type="submit" className="themeBtn" size="lg">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
