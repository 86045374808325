import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export const EditService = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const data = location?.state?.ServiceData
  return (
    <div>EditService</div>
  )
}
