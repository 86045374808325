import { dateFormat } from "../../../../utills/helpers/CommonHelper";
import Dropdown from "react-bootstrap/Dropdown";
import * as Icon from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

const ListItem = ({ list, handleEdit }) => {
  return (
    <>
      {list.length > 0 &&
        list.map((value, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{value.full_name}</td>
              <td>{value.email}</td>
              <td>
                {value.country_code} {value.mobile_no}
              </td>
              <td>{value.address}</td>
              <td>{value?.status}</td>
              <td>{value.role}</td>
              <td>{dateFormat(value.created_at)}</td>
            </tr>
          );
        })}
    </>
  );
};

export default ListItem;
