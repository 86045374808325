export const APP_NAME = "Planning Homes";
export const API_BASE_URL = "https://api.planninghomes.appwrk.com/api/";
export const IMAGE_BASE_URL = "https://api.planninghomes.appwrk.com/services";
// export const IMAGE_BASE_URL = "http://localhost:8000/api/services";
// export const API_BASE_URL = "http://localhost:8000/api/";

export const CALENDARLY_CLIENT_ID = "t3SOIq9PPcx_g9Qqj0beO2Tlw-ZIFq673R-0W4RIsaI";
export const CALENDARLY_CLIENT_SECRET = "4ZC7CRYCP6ND6626HJU64OJACAXV4QZZ"
export const CALENDARLY_WEBHOOK_SIGNING_KEY = "pfrQepFCzf0_iYHvRDen509vcympfYa1flUnwLBFXcE"
export const Token = "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk3MTc0MjU2LCJqdGkiOiI5MGMyNDUxOC04ODQ0LTRmNTUtODVhZi1lYjJiNTFhZWMxMDQiLCJ1c2VyX3V1aWQiOiJkNmI2ZTVlOS0xZWY0LTQxZTMtOTdjMC04ZTE4ZWUxZjdmNGMifQ.cTvPXX5UySFY3NYv1FmtdxspEoco-8pyaf_xoHLKgMroPQFNj1CBFNglpeWDWHQWWeQJuYXSljrmJYlsI3-Ubw"
