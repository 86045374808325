import React from 'react';
import IconHouseDesign from "../../../assets/images/admin/project_categories/house-design.png";
import Icon3DElivation from "../../../assets/images/admin/project_categories/3D-elivation.png";
import IconCommercial from "../../../assets/images/admin/project_categories/commercial.png";
import IconFloorDesign from "../../../assets/images/admin/project_categories/floor-design.png";
import IconInterior from "../../../assets/images/admin/project_categories/interior.png";
import IconPreferredFor from "../../../assets/images/admin/preferred_for.png";

// export const projectTypes = [
//         { icon: IconHouseDesign, name: "House Design", value: "House Design" },
//         { icon: IconCommercial, name: "Commercial", value: "Commercial" },
//         { icon: IconInterior, name: "Interior", value: "Interior" },
//         { icon: Icon3DElivation, name: "3D Elevation", value: "3delevation" },
//         { icon: IconFloorDesign, name: "Floor Plan", value: "floor_plan" },
//     ];

export const RoomTypes = [
        { icon: IconHouseDesign, name: "Bedroom", value: "Bedroom" },
        { icon: IconCommercial, name: "Kitchen", value: "Kitchen" },
        { icon: IconInterior, name: "Banquet", value: "Banquet" },
        { icon: Icon3DElivation, name: "Toilet", value: "Toilet" },
        { icon: IconFloorDesign, name: "Dining", value: "Dining" },
        { icon: IconFloorDesign, name: "Drawing", value: "Drawing" },
    ];

export const  NoOfTypes = [
        { name: 1, value: 1 },
        { name: 2, value: 2 },
        { name: 3, value: 3 },
        { name: 4, value: 4 },
        { name: 5, value: 5 },
        { name: 'N/A', value: 'Other' },
    ]

export const stairLocation = [
        { name: "Inside", value: "Inside" },
        { name: "OutSide", value: "OutSide" },
    ]

export const facingDropdown = [
        { name: 'East', value: 'East' },
        { name: 'West', value: 'West' },
        { name: 'North', value: 'North' },
        { name: 'South', value: 'South' },
        { name: 'North-East', value: 'North-East' },
        { name: 'North-West', value: 'North-West' },
        { name: 'South-East', value: 'South-East' },
        { name: 'South-West', value: 'South-West' },
    ]

export const otherFacing =  [
        { name: 'Corner', value: 'Corner' },
        { name: 'T Face', value: 'T Face' },
        { name: 'Three Side', value: 'Three Side' },
        { name: 'Other', value: 'Other' },
    ]

export const designType = [
        { name: 'Contemprary', value: 'Contemprary' },
        { name: 'Modern', value: 'Modern' },
        { name: 'Traditional', value: 'Traditional' },
        { name: 'Minimilistic', value: 'Minimilistic' },
        { name: 'Art Deco', value: 'Art Deco' },
        { name: 'Luxury', value: 'Luxury' },
        { name: 'Beach Style', value: 'Beach Style' },
        { name: 'Mid Century', value: 'Mid Century' },
        { name: 'Rustic', value: 'Rustic' },
        { name: 'Classic', value: 'Classic' },
        { name: 'Western ', value: 'Western' },
        { name: 'Indian', value: 'Indian' },
        { name: 'Other', value: 'Other' },
    ];

export const amenties = [
    {name:'Dining Table',value:'Dining Table',icon:'턒' },
    {name:'Washing Machine',value:'Washing Machine',icon:'톁' },
    {name:'Sofa',value:'Sofa',icon:'턝' },
    {name:'Microwave',value:'Microwave',icon:'턛' },
    {name:'Stove',value:'Stove',icon:'턞' },
    {name:'Fridge',value:'Fridge',icon:'턙' },
    {name:'Water Purifier',value:'Water Purifier',icon:'턓' },
];

export const preferredFor = [
    {name:'Small Famliy',value:'Small Famliy',icon:IconPreferredFor},
    {name:'Joint Family',value:'Joint Family',icon:IconPreferredFor},
    {name:'Rented Premises',value:'Rented Premises',icon:IconPreferredFor},
    {name:'Business Family',value:'Business Family',icon:IconPreferredFor},

];
export const plotArea = [
    {name:'1000 Sq.ft',value:'1000'},
    {name:'1000-2000 Sq.ft',value:'1000-2000'},
    {name:'2000-3000 Sq.ft',value:'2000-3000'},
    {name:'3000-4000 Sq.ft',value:'3000-4000'},
    {name:'4000 Sq.ft-above',value:'4000-above'},
]

export const buildingTypes = {
    "residential" : [
        {name : "Single Story",value :"Single Story"},
        {name: "Double Story", value : "Double Story"},
        {name: "Bunglow", value : "Bunglow"},
        {name: "Kothi", value : "Kothi"},
        {name: "Commercial House", value : "Commercial House"},
        {name: "Apartment House", value : "Apartment House"},
        {name: "Multi Family House", value : "Multi Family House"},
        {name: "Small House", value : "Small House"},
        {name: "Traditional House", value : "Traditional House"},
        {name: "Kerela Hut Shaped House", value : "Kerela Hut Shaped House"},
        {name: "Modern House", value : "Modern House"},
        {name: "FarmHouse", value : "FarmHouse"},
        {name: "Other", value : "Other"},
    ],
    "commercial" : [
        {name:"Apartments",value:'Apartments'},
        {name:"Shoping Complex",value:'Shoping Complex'},
        {name:"Corporate",value:'Corporate'},
        {name:"Hotels",value:'Hotels'},
        {name:"Hospitals",value:'Hospitals'},
        {name:"Restaurant",value:'Restaurant'},
        {name:"Showrooms",value:'Showrooms'},
        {name:"Banquets Hall",value:'Banquets Hall'},
        {name:"Gyms",value:'Gyms'},
        {name:"Rental",value:'Rental'},
        {name:"Other",value:'Other'},

    ],
    "institutional" : [
        {name:'Schools',value:'Schools'},
        {name:'Colleges',value:'Colleges'},
        {name:'Univerity',value:'Univerity'},
        {name:'ITI',value:'ITI'},
        {name:'Play School',value:'Play School'},
        {name:'Other',value:'Other'},

    ],
    "agriculture":[],
    "Government Buildings":[],
    "religious":[
        {name:'Temple',value:'Temple'},
        {name:'Gurudwara',value:'Gurudwara'},
        {name:'Masjid',value:'Masjid'},
        {name:'Church',value:'Church'},
    ],
    "industrial":[],
    "offices":[]
}

